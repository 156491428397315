import axios from 'axios';
import {useSWRConfig} from 'swr';
import useSWRImmutable from 'swr/immutable';
import {AVS, AVS_MENU, SHREEKUL, SHREEKUL_MENU} from '../utils/constants';
import shreekulBannerImage from '../assets/images/shreekul-banner.jpg';
import avsBannerImage from '../assets/images/avs-banner.jpg';

const fetcher = (url) => axios.get(url);

export default function useLayout() {
  const {apiUrl, siteName} = useSWRConfig();
  const url = `${apiUrl}`;
  const {data, error} = useSWRImmutable(url, fetcher);

  let menu = null;
  let profile = data?.data?.data.setting;

  if (profile) {
    profile.siteName = siteName;

    if (siteName === SHREEKUL) {
      profile.primary = '#941122';
      profile.secondary = '#f7ab30';
      profile.bannerImage = shreekulBannerImage;
      profile.googleMap =
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.7954952377268!2d85.30399355104939!3d27.661798234013833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18291caa6141%3A0x3bcbbbbb56dcd8d2!2sShreekul%20School!5e0!3m2!1sen!2snp!4v1652793769696!5m2!1sen!2snp';
      menu = SHREEKUL_MENU;

      // append about menu
      let siteMenu = data?.data?.data.menu;
      if (siteMenu && siteMenu['About']) {
        let aboutMenu = {name: 'About', subMenu: []};
        for (const [key, value] of Object.entries(siteMenu['About'])) {
          aboutMenu.subMenu.push({name: key, slug: value});
        }
        aboutMenu.subMenu.splice(2, 0, {
          name: 'Faculty/Team',
          slug: 'about/team',
        });
        menu.splice(1, 0, aboutMenu);
      }
    }

    if (siteName === AVS) {
      profile.primary = '#00388d';
      profile.secondary = '#e31723';
      profile.applyLink =
        'https://docs.google.com/forms/d/e/1FAIpQLScfxJtRlKNEAXByD15oXL7GnrLQAHztWRHQBRf6s9lx90seZg/viewform';
      profile.bannerImage = avsBannerImage;
      profile.googleMap =
        'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d56539.400197001596!2d85.331645!3d27.664365!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ea8cb6be32d788!2sAadarsh%20Vidhya%20Sadan%20(AVS%20school)!5e0!3m2!1sen!2snp!4v1653114185777!5m2!1sen!2snp';
      menu = AVS_MENU;
    }
  }

  return {
    layout: {
      profile: profile || {},
      menu: menu || [],
    },
    isLoading: !error && !data,
    isError: error,
  };
}
