import React, {useEffect} from 'react';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import {makeStyles} from '@mui/styles';
import HTMLReactParser from 'html-react-parser';
import usePost from '../hooks/use-post';
import {useNavigate, useParams} from 'react-router-dom';
import {SHREEKUL} from '../utils/constants';
import BannerImage from '../components/banner-image/1';

const useStyles = makeStyles((theme) => ({
  detailPageBg: {
    padding: theme.siteName === SHREEKUL ? '150px 0 50px' : '90px 0 50px',
  },
  detailPageMain: {
    '& .content-holder': {
      position: 'relative',
      '& .detail': {
        '& .detail-title': {
          fontSize: '32px',
          color: '#434343',
          fontWeight: 700,
          marginBottom: '20px',
        },
        '& .img-holder': {
          marginBottom: '30px',
          '& img': {
            height: 'auto',
            width: '100%',
          },
        },
        '& .description': {
          lineHeight: 1.7,
          fontSize: '18px',
          color: '#73738c',
          '& p': {
            '& strong': {
              color: '#434343',
            },
            '&:not(:last-child)': {
              marginBottom: '15px',
            },
            '& img': {
              width: 'auto',
              objectFit: 'contain',
            },
            '& .btn-outline-primary': {
              color: '#fff',
              borderRadius: '10px',
              background: '#009f86',
              boxShadow: `0 10px 50px #009f864f`,
              transition: 'all .3s ease-out',
              border: 'none',
              padding: '8px 20px',
              textDecoration: 'none',
              '&:hover': {
                background: '#219ebc',
                boxShadow: `0 10px 50px #219ebc4f`,
              },
            },
          },
          '& img': {
            width: '100% !important',
            objectFit: 'contain',
          },
          '& h1, h2, h3, h4, h5, h6': {
            color: '#009f86',
          },
          '& table': {
            border: 'none',
            marginTop: '20px',
            '& tbody': {
              '& tr': {
                '& th': {
                  padding: '10px',
                  background: '#009f86',
                  color: '#fff',
                  border: `1px solid red`,
                  '&:not(:last-child)': {
                    borderRight: '1px solid #73738c',
                    borderBottom: 'none',
                  },
                },
                '& td': {
                  padding: '10px',
                  border: '1px solid #73738c',
                },
              },
            },
          },
          '& ul, ol': {
            '& li': {
              position: 'relative',
              paddingLeft: '15px',
              '&:not(:last-child)': {
                marginBottom: '10px',
              },
              '&:before': {
                content: "''",
                position: 'absolute',
                top: '50%',
                transform: 'translate(0, -50%)',
                left: '0',
                width: '6px',
                height: '6px',
                background: '#73738c',
                borderRadius: '50%',
              },
            },
          },
        },
        '& .button': {
          display: 'inline-block',
          '& .btn-outline-danger': {
            background: '#219ebc',
            color: '#fff',
            borderRadius: '10px',
            border: 'none',
            boxShadow: `0 10px 50px #219ebc4f`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all .3s ease-out',
            '& .MuiSvgIcon-root': {
              marginLeft: '5px',
              fontSize: '22px',
            },
            '&:hover': {
              background: '#009f86',
              boxShadow: `0 10px 50px #009f864f`,
            },
          },
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .sideMenu': {
          display: 'none',
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        '& .sideMenu': {
          display: 'none',
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .sideMenu': {
          display: 'block',
        },
      },
    },
  },
}));

const Detail = () => {
  const {module, slug} = useParams();
  const isPost = module === 'kids-corner' || module === 'blogs';
  const {post, isLoading, resourcePath} = usePost(
    isPost ? 'posts' : module,
    slug
  );
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    if (!isLoading && !post) {
      navigate('/404');
    }
  }, [isLoading, post, navigate]);

  return (
    <>
      {module === 'notices' ? <BannerImage title={'Our Notices'} /> : null}
      <section className={`${classes.detailPageBg}`}>
        <div className={`container-fluid ${classes.detailPageMain}`}>
          <div className="content-holder">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {post ? (
                  <div className="detail">
                    <h2 className="detail-title">{post.title}</h2>
                    <div className="detail-content-page">
                      {post.image ? (
                        <div className="img-holder">
                          <img
                            src={`${resourcePath}/${post.image}`}
                            alt={post.title}
                            className="img img-fluid"
                          />
                        </div>
                      ) : null}
                      <div className="description">
                        {HTMLReactParser(post.description || '')}
                      </div>
                      {post.file ? (
                        <div className="button mt-3">
                          <a
                            href={post.file}
                            download={post.file}
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-outline-danger"
                          >
                            {post.title}
                            <CloudDownloadRoundedIcon />
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Detail;
