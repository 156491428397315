import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Footer1 from './components/footer/1';
import Home from './pages/home';
import Header2 from './components/header/2';
import GalleryList from './pages/gallery-list';
import GalleryDetailPage from './pages/gallery-detail';
import List from './pages/list';
import Detail from './pages/detail';
import Contact from './components/contact/1';
import generateTheme from './functions/generate-theme';
import {ThemeProvider} from '@mui/styles';
import WebsiteContext from './contexts/website-context';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import VideoList from './pages/video-list';
import TestimonialList from './pages/testimonial-list';
import TeamList from './pages/team-list';
import PageScrollToTop from './utils/ScrollToTop';
import NotFound1 from './components/404/1';
import ScrollToTop from 'react-scroll-to-top';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Calendar from './pages/calendar';
import Downloads from './pages/downloads';
import PostScroll from './pages/post-scroll';
import AboutDetail from './pages/about-detail';

const App = ({website}) => {
  const theme = generateTheme(website.profile);

  return (
    <ThemeProvider theme={theme}>
      <WebsiteContext.Provider value={website}>
        <HelmetProvider>
          <Helmet>
            <title>{website.profile.title}</title>
            <link
              rel="shortcut icon"
              href={`${website.profile.logo_path.replace(
                '/logo',
                '/favicon'
              )}/${website.profile.favicon}`}
            />
          </Helmet>
          <BrowserRouter>
            <PageScrollToTop>
              <div className="wrapper">
                <Header2 />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/404" element={<NotFound1 />} />
                  <Route path="/about/team" element={<TeamList />} />
                  <Route path="/about/:slug" element={<AboutDetail />} />
                  <Route path="/testimonials" element={<TestimonialList />} />
                  <Route path="/gallery/photo" element={<GalleryList />} />
                  <Route
                    path="/gallery/photo/:id"
                    element={<GalleryDetailPage />}
                  />
                  <Route path="/gallery/video" element={<VideoList />} />
                  <Route path="/contact-us" element={<Contact />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/downloads" element={<Downloads />} />
                  <Route path="/blogs" element={<PostScroll />} />
                  <Route path="/:module" element={<List />} />
                  <Route path="/:module/:slug" element={<Detail />} />
                  <Route path="*" element={<NotFound1 />} />
                </Routes>
                <Footer1 />
              </div>
            </PageScrollToTop>
            <ScrollToTop
              smooth
              style={{
                background: theme.palette.secondary.main,
                borderRadius: '50%',
              }}
              component={
                <ArrowUpwardIcon style={{color: '#fff', fontSize: '22px'}} />
              }
            />
          </BrowserRouter>
        </HelmetProvider>
      </WebsiteContext.Provider>
    </ThemeProvider>
  );
};

export default App;
