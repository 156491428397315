import React from 'react';
import {makeStyles} from '@mui/styles';
import BannerImage from '../components/banner-image/1';
import useCalendar from '../hooks/use-calendar';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  detailPageBg: {
    padding: '90px 0 50px',
  },
  detailPageMain: {
    '& .content-holder': {
      position: 'relative',
      '& .detail': {
        '& .detail-title': {
          fontSize: '32px',
          color: '#434343',
          fontWeight: 700,
          marginBottom: '20px',
        },
        '& .img-holder': {
          marginBottom: '30px',
          '& img': {
            height: 'auto',
            width: '100%',
          },
        },
        '& .description': {
          marginBottom: '40px',
          lineHeight: 1.7,
          fontSize: '18px',
          color: '#73738c',
          '& p': {
            '& strong': {
              color: '#434343',
            },
            '&:not(:last-child)': {
              marginBottom: '15px',
            },
            '& img': {
              width: 'auto',
              objectFit: 'contain',
            },
            '& .btn-outline-primary': {
              color: '#fff',
              borderRadius: '10px',
              background: '#009f86',
              boxShadow: `0 10px 50px #009f864f`,
              transition: 'all .3s ease-out',
              border: 'none',
              padding: '8px 20px',
              textDecoration: 'none',
              '&:hover': {
                background: '#219ebc',
                boxShadow: `0 10px 50px #219ebc4f`,
              },
            },
          },
          '& img': {
            width: '100% !important',
            objectFit: 'contain',
          },
          '& h1, h2, h3, h4, h5, h6': {
            color: theme.palette.primary.main,
          },
          '& table': {
            border: 'none',
            marginTop: '20px',
            '& tbody': {
              '& tr': {
                '& th': {
                  padding: '10px',
                  background: '#009f86',
                  color: '#fff',
                  border: `1px solid red`,
                  '&:not(:last-child)': {
                    borderRight: '1px solid #73738c',
                    borderBottom: 'none',
                  },
                },
                '& td': {
                  padding: '10px',
                  border: '1px solid #73738c',
                },
              },
            },
          },
        },
        '& .button': {
          display: 'inline-block',
          '& .btn-outline-danger': {
            background: '#219ebc',
            color: '#fff',
            borderRadius: '10px',
            border: 'none',
            boxShadow: `0 10px 50px #219ebc4f`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all .3s ease-out',
            '& .MuiSvgIcon-root': {
              marginLeft: '5px',
              fontSize: '22px',
            },
            '&:hover': {
              background: '#009f86',
              boxShadow: `0 10px 50px #009f864f`,
            },
          },
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .sideMenu': {
          display: 'none',
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        '& .sideMenu': {
          display: 'none',
        },
      },
      '& .detail-content-page': {
        display: 'flex',
        flexWrap: 'wrap',

        '& .description': {
          flexBasis: '50%',
          paddingRight: '30px',
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .sideMenu': {
          display: 'block',
        },
      },
    },
  },
}));

const Calendar = () => {
  const {posts: calendar, resourcePath} = useCalendar();
  const classes = useStyles();
  return (
    <>
      <BannerImage
        title={calendar.length > 0 ? 'Calendar - ' + calendar[0].year : ''}
      />
      <section className={`${classes.detailPageBg}`}>
        <div className={`container-fluid ${classes.detailPageMain}`}>
          <div className="content-holder">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="detail">
                  <div className="detail-content-page">
                    {calendar.map((cl) => {
                      const monthName = dayjs()
                        .month(Number(cl.month) - 1)
                        .format('MMMM');
                      return (
                        <div className="description" key={cl.id}>
                          <div className="img-holder">
                            <img
                              src={`${resourcePath.replace(
                                'calendars',
                                'calendar'
                              )}/${cl.image}`}
                              alt={monthName}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Calendar;
