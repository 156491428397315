import axios from 'axios';
import {useSWRConfig} from 'swr';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url) => axios.get(url);

export default function useAbout(slug) {
  const url = `${useSWRConfig().apiUrl}/about/${slug}`;
  const {data, error} = useSWRImmutable(url, fetcher);

  return {
    post: data?.data?.data,
    isLoading: !error && !data,
    isError: error,
  };
}
