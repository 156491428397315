import axios from 'axios';
import {useSWRConfig} from 'swr';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url) => axios.get(url);

export default function useHome() {
  const {apiUrl} = useSWRConfig();
  const url = `${apiUrl}`;
  const {data, error} = useSWRImmutable(url, fetcher);

  let homeData = data?.data?.data || {};
  let home = {...homeData};

  if (home.setting) {
    delete home.setting;
  }

  for (let modelKey in home) {
    // prevent nested property removal from parent by shallow clone
    let modelData = {...home[modelKey]};
    let resourcePath = modelData['image_path'];
    if (resourcePath) {
      delete modelData['image_path'];
    }
    let data = Object.values(modelData);
    home[modelKey] = {resourcePath, data};
  }

  return {
    home,
    isLoading: !error && !data,
    isError: error,
  };
}
