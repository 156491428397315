import React, {useState, useEffect, useContext} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {makeStyles, useTheme} from '@mui/styles';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import Dropdown from 'react-bootstrap/Dropdown';
import MobileMenu from '../mobile-menu/1';
import WebsiteContext from '../../../contexts/website-context';

const useStyles = makeStyles((theme) => ({
  headerDetail: {
    background: `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
  },
  headerBg: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  '@media(max-width: 600px)': {
    headerBg: {
      display: 'none',
    },
  },
  '@media(min-width: 768px)': {
    headerBg: {
      display: 'none',
    },
  },
  '@media(min-width: 992px)': {
    headerBg: {
      display: 'block',
    },
  },
  topHeader: {
    background: theme.palette.primary.main,
    padding: '8px 0',
    fontSize: '15px',
    color: '#fff',
    '& .list-inline-item:not(:last-child)': {
      marginRight: '15px',
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
    },
  },
  headerMain: {
    '& .content-holder': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .img-holder': {
        '& a': {
          '& img': {
            maxHeight: '70px',
            filter: 'brightness(0) invert(1)',
          },
        },
      },
      '& .navigation': {
        '& .main-navigation': {
          margin: 0,
          padding: 0,
          '& .main-navigation-list': {
            listStyle: 'none',
            display: 'inline-block',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: '30px',
              left: '20px',
              width: '0%',
              opacity: '0',
              height: '3px',
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '15px',
              transition: 'all 0.3s ease-in-out',
            },
            '& .main-navigation-list-link': {
              textDecoration: 'none',
              color: '#fff',
              padding: '30px 20px',
              display: 'block',
              cursor: 'pointer',
              fontSize: '16px',
            },
            '& .main-navigation-submenu': {
              margin: 0,
              padding: 0,
              position: 'absolute',
              width: '100%',
              minWidth: '300px',
              background: '#fff',
              boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
              display: 'none',
              transition: 'all 0.3s ease-out',
              animation: `$fadeIn 0.3s ease-out`,
              '& .main-navigation-submenu-list': {
                listStyle: 'none',
                position: 'relative',
                transition: 'all 0.3s ease-out',
                '& .main-navigation-submenu-list-link': {
                  textDecoration: 'none',
                  color: '#434343',
                  padding: '10px 20px',
                  fontSize: '16px',
                  display: 'block',
                  transition: 'all 0.3s ease-out',
                  cursor: 'pointer',
                },
                '& .main-navigation-submenu-menuLine': {
                  margin: 0,
                  padding: 0,
                  position: 'absolute',
                  top: '0',
                  left: '100%',
                  width: '100%',
                  background: '#fff',
                  boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
                  display: 'none',
                  transition: 'all 0.3s ease-out',
                  animation: `$fadeRight 0.3s ease-out`,
                  '& .main-navigation-submenu-menuLine-list': {
                    listStyle: 'none',
                    transition: 'all 0.3s ease-out',
                    '& .main-navigation-submenu-menuLine-list-link': {
                      textDecoration: 'none',
                      color: '#434343',
                      padding: '10px 20px',
                      display: 'block',
                      fontSize: '16px',
                      transition: 'all 0.3s ease-out',
                    },
                    '&:hover': {
                      background: theme.palette.secondary.main,
                      '& .main-navigation-submenu-menuLine-list-link': {
                        color: '#fff',
                        paddingLeft: '30px',
                      },
                    },
                  },
                },
                '&:hover': {
                  background: theme.palette.secondary.main,
                  '& .main-navigation-submenu-menuLine': {
                    display: 'block',
                  },
                  '& .main-navigation-submenu-list-link': {
                    paddingLeft: '30px',
                    color: '#fff',
                  },
                },
              },
            },
            '&:hover': {
              '&::before': {
                opacity: 1,
                width: '50%',
              },
              '& .main-navigation-submenu': {
                display: 'block',
              },
            },
          },
        },
      },
      '& .login-buttons': {
        position: 'relative',
        '& .btn-primary': {
          background: 'transparent',
          border: 'none !important',
          display: 'flex',
          alignItems: 'center',
          transition: 'all .3s ease-out',
          '&:hover': {
            color: theme.palette.secondary.main,
          },
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
            marginRight: '5px',
          },
        },
        '& .top-header-link': {
          color: '#434343',
          background: 'transparent',
          outline: 'none',
          fontSize: '14px',
          position: 'relative',
          transition: 'all .3s ease-out',
          padding: '7px 15px',
          '&:hover': {
            color: theme.palette.secondary.main,
            background: 'transparent !important',
            paddingLeft: '20px',
          },
        },
        '& .dropdown-menu': {
          minWidth: '180px',
        },
        '& .dropdown-menu.show': {
          marginTop: '10px !important',
          border: 'none !important',
          boxShadow: '8px 8px 30px 0px rgb(42 67 113 / 15%)',
          borderRadius: '10px',
          position: 'relative',
          transform: 'translate(0px, 32px) !important',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: '-10px',
            left: '30%',
            transform: 'translateX(-50%)',
            width: '0',
            height: '0',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid #fff',
          },
        },
        '& .btn-primary:focus': {
          boxShadow: 'none !important',
        },
        '& .btn-primary:hover, .btn-primary:focus, .btn-primary:active': {
          background: 'transparent !important',
        },
      },
    },
  },
  '@keyframes fadeIn': {
    from: {
      transform: 'translateY(20px)',
    },
    to: {
      transform: 'translateY(0px)',
    },
  },
  '@keyframes fadeRight': {
    from: {
      transform: 'translateX(-20px)',
    },
    to: {
      transform: 'translateX(0px)',
    },
  },
}));

const Header2 = () => {
  const {profile, menu} = useContext(WebsiteContext);
  const [headerClass, setHeaderClass] = useState('');
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  window.onscroll = function () {
    scrollFunction();
  };

  let navStyle = '';

  function scrollFunction() {
    let className = `${classes.headerBg}`;
    if (
      document.body.scrollTop > 90 ||
      document.documentElement.scrollTop > 90
    ) {
      navStyle = `{
                background: linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%) !important;
                position: fixed !important; 
                z-index: 9 !important; top: 0; 
                backdrop-filter: blur(6px) !important;
                left: 0 !important; 
                right: 0 !important; 
                animation: slideIn 2s; 
                transition: transform 1s ease-in-out 0s; 
                animation-timing-function: ease-out;
                animation-duration: .7s;
            }`;
      let keyFrame = `@keyframes slideIn { 
                0% {
                    opacity: 0;
                    top: -30px;
                }
	            100% {
                    opacity: 1;
                    top: 0;
                } 
        }`;

      let extraStyle = `.top-header{display:none}`;

      document.getElementById('nav-style').innerHTML =
        '.' + className + navStyle + keyFrame + extraStyle;
    } else {
      navStyle = `{ 
            }`;
      document.getElementById('nav-style').innerHTML =
        '.' + className + navStyle;
    }
  }

  const startsWith = function (text, textArray) {
    let match = false;

    for (let i = 0; i < textArray.length - 1; i++) {
      if (text.startsWith(textArray[i])) {
        match = true;
        break;
      }
    }

    return match;
  };

  useEffect(() => {
    const paths = ['/'];
    const subPaths = ['/:slug'];
    const detailClass =
      paths.indexOf(location.pathname) === -1 &&
      !startsWith(location.pathname, subPaths)
        ? `${classes.headerDetail}`
        : '';
    setHeaderClass(detailClass);
  }, [location]);

  return (
    <>
      <MobileMenu profile={profile} menu={menu} />
      <section className={`${classes.headerBg} ${headerClass}`}>
        {/* <div className={`${classes.topHeader} top-header`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <div className="me-2">Phone:</div>
                <a href={`tel:${profile.phone}`}>{profile.phone}</a>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <div className="me-2">Follow Us:</div>
                <ul className="list-inline mb-0">
                  {profile.fb_url ? (
                    <li className="list-inline-item">
                      <a
                        href={profile.fb_url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                  ) : null}
                  {profile.yt_url ? (
                    <li className="list-inline-item">
                      <a
                        href={profile.yt_url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  ) : null}
                  {profile.tw_url ? (
                    <li className="list-inline-item">
                      <a
                        href={profile.tw_url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                  ) : null}
                  {profile.insta_url ? (
                    <li className="list-inline-item">
                      <a
                        href={profile.insta_url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        <div className={`container-fluid ${classes.headerMain}`}>
          <div className="content-holder">
            <div className="img-holder">
              <Link to="/">
                <img
                  src={`${profile.logo_path}/${profile.logo}`}
                  alt={`${profile.title} Logo`}
                />
              </Link>
            </div>
            <nav className="navigation">
              <ul className="main-navigation">
                {menu.map((item, i) => {
                  if (item?.subMenu) {
                    return (
                      <li className="main-navigation-list" key={i + '__'}>
                        <span className="main-navigation-list-link">
                          {item.name}
                        </span>
                        <ul className="main-navigation-submenu">
                          {item.subMenu.map((subItem, j) => {
                            return subItem?.menuLine ? (
                              <li
                                className="main-navigation-submenu-list"
                                key={subItem.name + i}
                              >
                                <span className="main-navigation-submenu-list-link">
                                  {subItem.name}
                                </span>
                                <ul className="main-navigation-submenu-menuLine">
                                  {subItem.menuLine.map((menuLineItem, i) => {
                                    return (
                                      <li
                                        className="main-navigation-submenu-menuLine-list"
                                        key={menuLineItem.name + i}
                                      >
                                        <Link
                                          to={menuLineItem.slug}
                                          className="main-navigation-submenu-menuLine-list-link"
                                        >
                                          {menuLineItem.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            ) : (
                              <li
                                className="main-navigation-submenu-list"
                                key={subItem.name + '___'}
                              >
                                <Link
                                  to={subItem.slug}
                                  className="main-navigation-submenu-list-link"
                                >
                                  {subItem.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <li
                        className="main-navigation-list"
                        key={item.name + i + '_'}
                      >
                        <Link
                          to={item.slug}
                          className="main-navigation-list-link"
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
            </nav>
            <div className="login-buttons">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-autoclose-true">
                  <LockOpenRoundedIcon />
                  PORTAL
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    href="https://edigitalnepal.com/sms/login?role=admin"
                    target="_blank"
                    className="top-header-link"
                  >
                    Admin Login
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="https://edigitalnepal.com/sms/login?role=teacher"
                    target="_blank"
                    className="top-header-link"
                  >
                    Teacher Login
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="https://edigitalnepal.com/sms/login?role=student"
                    target="_blank"
                    className="top-header-link"
                  >
                    Student / Parent Login
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header2;
