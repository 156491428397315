import React from 'react';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal';
// import dayjs from 'dayjs';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Slider from 'react-slick';
import Image from '../../../assets/images/bg-img.jpg';

const useStyles = makeStyles((theme) => ({
  newsSectionBg: {
    position: 'relative',
  },
  '@media(max-width: 600px)': {
    newsSectionBg: {
      padding: '50px 0',
    },
  },
  '@media(min-width: 768px)': {
    newsSectionBg: {
      padding: '50px 0',
    },
  },
  '@media(min-width: 992px)': {
    newsSectionBg: {
      padding: '200px 0 75px 0',
    },
  },
  newsSectionMain: {
    '& .content-holder': {
      '& .text-holder': {
        position: 'relative',
        marginBottom: '30px',
        textAlign: 'center',
        '& .sub-title': {
          fontSize: '18px',
          color: theme.palette.secondary.main,
          marginBottom: '0',
        },
        '& .title': {
          fontSize: '32px',
          fontWeight: '700',
          color: '#434343',
        },
      },
      '& .slick-prev': {
        left: '92%',
        top: '-60px',
        zIndex: '1',
        '&::before': {
          content: '"\\f104"',
          color: '#009f86',
          border: `1px solid ${theme.palette.secondary.main}`,
          height: '30px',
          width: '30px',
          display: 'grid',
          placeItems: 'center',
          borderRadius: '5px',
          font: 'normal normal normal 22px/1 FontAwesome',
          transition: 'all 0.3s ease-out',
        },
        '&:hover': {
          '&::before': {
            background: theme.palette.primary.main,
            color: '#fff',
          },
        },
      },
      '& .slick-next': {
        right: '25px',
        top: '-60px',
        '&::before': {
          content: '"\\f105"',
          color: '#009f86',
          border: `1px solid ${theme.palette.secondary.main}`,
          height: '30px',
          width: '30px',
          display: 'grid',
          placeItems: 'center',
          borderRadius: '5px',
          font: 'normal normal normal 22px/1 FontAwesome',
          transition: 'all 0.3s ease-out',
        },
        '&:hover': {
          '&::before': {
            background: theme.palette.primary.main,
            color: '#fff',
          },
        },
      },
      '& .slick-dots': {
        '& li': {
          width: 'unset',
          height: 'unset',
          '& button': {
            width: '15px',
            height: '8px',
            opacity: 0.5,
            padding: 0,
            background: theme.palette.primary.main,
            transition: 'all .3s ease-out',
            borderRadius: '50px',
            '&::before': {
              display: 'none',
            },
          },
        },
        '& .slick-active': {
          '& button': {
            width: '30px',
            background: theme.palette.secondary.main,
            opacity: 1,
            '&::before': {
              display: 'none',
            },
          },
        },
      },
      '& .marginBottom': {
        '& .news-list': {
          boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
          background: '#fff',
          borderRadius: '15px',
          transition: 'all .3s ease-out',
          '& .img-holder': {
            height: '250px',
            width: '100%',
            overflow: 'hidden',
            borderRadius: '15px 15px 0 0',
            '& a': {
              position: 'relative',
              '& .bg-stretch': {
                height: '100%',
                width: '100%',
                borderRadius: '15px 15px 0 0',
                transition: 'all 2s ease-out',
              },
              '& .date-holder': {
                position: 'absolute',
                bottom: 0,
                right: 0,
                background: '#219ebc',
                padding: '10px',
                borderRadius: '15px 0 0 0',
                '& span': {
                  color: '#fff',
                  fontWeight: 500,
                  fontSize: '18px',
                },
              },
            },
          },
          '& .content': {
            padding: '20px',
            '& .news-title': {
              marginBottom: '10px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              '& a': {
                textDecoration: 'none',
                color: '#434343',
                fontSize: '18px',
                fontWeight: 700,
                transition: 'all 0.3s ease-out',
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              },
            },
            '& .description': {
              color: '#73738c',
              fontSize: '16px',
              lineHeight: 1.7,
              marginBottom: '15px',
            },
            '& .read-more-button': {
              '& .btn-outline-primary': {
                background: '#fff',
                border: `1px solid ${theme.palette.secondary.main}`,
                borderRadius: '10px',
                color: theme.palette.secondary.main,
                boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
                minWidth: '120px',
                padding: '10px 20px',
                fontSize: '16px',
                fontWeight: 500,
                position: 'relative',
                transition: 'all 0.3s ease-out',
                paddingRight: '40px',
                '& .icon-opacity': {
                  fontSize: '20px',
                  position: 'absolute',
                  left: '71.5%',
                  top: '50%',
                  transform: 'translate(0, -50%)',
                  transition: 'all 0.3s ease-out',
                },
                '& .icon-opacity2': {
                  opacity: 0,
                  fontSize: '20px',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(0, -50%)',
                  transition: 'all 0.3s ease-out',
                },
                '&:hover': {
                  background: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                  color: '#fff',
                  '& .icon-opacity': {
                    opacity: 0,
                    left: '50%',
                  },
                  '& .icon-opacity2': {
                    opacity: 1,
                    left: '71.5%',
                  },
                },
              },
            },
          },
          '&:hover': {
            '& .img-holder': {
              '& a': {
                '& .bg-stretch': {
                  transform: 'scale(2)',
                },
              },
            },
          },
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '& .center': {
            margin: '0 0 20px',
          },
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '& .center': {
            margin: '0 15px 20px',
          },
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '& .center': {
            margin: '0 15px 20px',
          },
        },
      },
    },
  },
}));

const NewsSection = ({data: blogs, resourcePath}) => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: blogs.length > 3 ? 3 : blogs.length,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: blogs.length > 2 ? 2 : blogs.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return blogs.length > 0 ? (
    <section
      className={`${classes.newsSectionBg}`}
      style={{backgroundImage: `url('${Image}')`}}
    >
      <div className={`container-fluid ${classes.newsSectionMain}`}>
        <div className="content-holder">
          <Fade bottom duration={2000}>
            <div className="text-holder">
              <h4 className="sub-title">Blog</h4>
              <h2 className="title">Our Blogs</h2>
            </div>
          </Fade>
          <Slider {...settings}>
            {blogs.map((item, i) => {
              return (
                <Fade bottom duration={2000} key={i + '___'}>
                  <div className="news-list marginBottom">
                    <div className="news-list center">
                      <div className="img-holder">
                        <Link to={`/blogs/${item.slug}`}>
                          <div
                            className="bg-stretch background-image"
                            style={{
                              backgroundImage: `url('${resourcePath}/${item.image}')`,
                            }}
                          />
                          {/* <div className="date-holder">
                            <span className="date">
                              {dayjs().format('DD MMM, YYYY')}
                            </span>
                          </div> */}
                        </Link>
                      </div>
                      <div className="content">
                        <div className="news-title">
                          <Link to={`/blogs/${item.slug}`}>{item.title}</Link>
                        </div>
                        <p className="description">
                          {item.summary.length > 100
                            ? item.summary.substring(0, 100) + '...'
                            : item.summary}
                        </p>
                        <div className="read-more-button">
                          <Link
                            to={`/blogs/${item.slug}`}
                            className="btn btn-outline-primary"
                          >
                            Read More{' '}
                            <ArrowForwardRoundedIcon className="icon-opacity" />
                            <ArrowForwardRoundedIcon className="icon-opacity2" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  ) : null;
};
export default NewsSection;
