import React, {Component, useEffect} from 'react';
import {withStyles} from '@mui/styles';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {useNavigate, useParams} from 'react-router-dom';
import BannerImage from '../components/banner-image/1';
import {useSWRConfig} from 'swr';
import useGallery from '../hooks/use-gallery';

// style
const styles = (theme) => ({
  galleryDetailBg: {
    padding: '90px 0 50px',
  },
  galleryDetailMain: {
    '& .content-holder': {
      '& .text-holder': {
        marginBottom: '50px',
        textAlign: 'center',
        '& .title': {
          fontSize: '32px',
          fontWeight: '700',
          color: '#434343',
        },
      },
      '& .grid-wrapper': {
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: 'repeat(auto-fit, minmax(270px, 1fr))',
        gridAutoRows: '250px',
        gridAutoFlow: 'dense',
        '& .blog-grid': {
          position: 'relative',
          cursor: 'pointer',
          overflow: 'hidden',
          borderRadius: '15px',
          '& .bg-stretch': {
            position: 'relative',
            maxWidth: '100%',
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            transition: 'all 2s ease-out',
          },
          '&:hover': {
            '& .bg-stretch': {
              transform: 'scale(1.7)',
            },
          },
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        '& .grid-wrapper': {
          '& .blog-grid': {
            '&:nth-child(1)': {
              gridRow: 'span 2',
            },
            '&:nth-child(4)': {
              gridColumn: 'span 2',
            },
            '&:nth-child(5)': {
              gridColumn: 'span 2',
            },
          },
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .grid-wrapper': {
          '& .blog-grid': {
            '&:nth-child(1)': {
              gridRow: 'span 2',
            },
            '&:nth-child(4)': {
              gridColumn: 'span 2',
            },
            '&:nth-child(5)': {
              gridColumn: 'span 1',
            },
          },
        },
      },
    },
    '@media(min-width: 1400px)': {
      '& .content-holder': {
        '& .grid-wrapper': {
          '& .blog-grid': {
            '&:nth-child(1)': {
              gridRow: 'span 2',
            },
            '&:nth-child(4)': {
              gridColumn: 'span 2',
            },
            '&:nth-child(5)': {
              gridColumn: 'span 2',
            },
          },
        },
      },
    },
    '@media(min-width: 1600px)': {
      '& .content-holder': {
        '& .grid-wrapper': {
          '& .blog-grid': {
            '&:nth-child(1)': {
              gridRow: 'span 2',
            },
            '&:nth-child(4)': {
              gridColumn: 'span 2',
            },
            '&:nth-child(5)': {
              gridColumn: 'span 2',
            },
          },
        },
      },
    },
    '@media(min-width: 1920px)': {
      '& .content-holder': {
        '& .grid-wrapper': {
          '& .blog-grid': {
            '&:nth-child(1)': {
              gridRow: 'span 2',
            },
            '&:nth-child(4)': {
              gridColumn: 'span 2',
            },
            '&:nth-child(5)': {
              gridColumn: 'span 2',
            },
          },
        },
      },
    },
    '@media(min-width: 2550px)': {
      '& .content-holder': {
        '& .grid-wrapper': {
          '& .blog-grid': {
            '&:nth-child(1)': {
              gridRow: 'span 2',
            },
            '&:nth-child(4)': {
              gridColumn: 'span 2',
            },
            '&:nth-child(5)': {
              gridColumn: 'span 2',
            },
          },
        },
      },
    },
  },
});

class GalleryDetailClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const {photoIndex, isOpen} = this.state;
    const {classes, gallery, isLoading, resourcePath} = this.props;
    const galleryItems = gallery?.gallery_images || [];

    return (
      <>
        <BannerImage
          baseMenu={{title: 'Photo Gallery', link: '/gallery/photo'}}
          title={gallery?.title}
        />
        {galleryItems ? (
          <section className={`${classes.galleryDetailBg}`}>
            <div className={`container-fluid ${classes.galleryDetailMain}`}>
              <div className="content-holder">
                {/* <div className="text-holder">
                  <h2 className="title">{gallery.title}</h2>
                </div> */}
                {isOpen && (
                  <Lightbox
                    mainSrc={`${resourcePath}/${galleryItems[photoIndex].image}`}
                    nextSrc={`${resourcePath}/${
                      galleryItems[(photoIndex + 1) % galleryItems.length].name
                    }`}
                    prevSrc={`${resourcePath}/${
                      galleryItems[
                        (photoIndex + galleryItems.length - 1) %
                          galleryItems.length
                      ].name
                    }`}
                    onCloseRequest={() => this.setState({isOpen: false})}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex:
                          (photoIndex + galleryItems.length - 1) %
                          galleryItems.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % galleryItems.length,
                      })
                    }
                    imageTitle={gallery.description}
                  />
                )}
                <div>
                  {!isLoading && galleryItems.length === 0
                    ? 'Sorry, there are no photos in this gallery.'
                    : ''}
                </div>
                <div className="grid-wrapper">
                  {galleryItems.map((item, i) => {
                    return (
                      <div className="blog-grid" key={i + '__'}>
                        <div
                          className="bg-stretch background-image"
                          onClick={() =>
                            this.setState({isOpen: true, photoIndex: i})
                          }
                          style={{
                            backgroundImage: `url('${resourcePath}/${item.image}')`,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </>
    );
  }
}

const GalleryDetailWithClass = withStyles(styles, {withTheme: true})(
  GalleryDetailClass
);

const GalleryDetailPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const resourcePath = useSWRConfig().apiUrl.replace(
    '/api',
    '/uploads/gallery'
  );
  const {posts: gallery, isLoading} = useGallery('photo', id);

  useEffect(() => {
    if (!isLoading && !gallery) {
      navigate('/404');
    }
  }, [isLoading, gallery, navigate]);

  return (
    <GalleryDetailWithClass
      gallery={gallery}
      isLoading={isLoading}
      resourcePath={resourcePath}
    />
  );
};

export default GalleryDetailPage;
