import Introduction from '../components/introduction/1';
import CounterBlock from '../components/counter-block/1';
import TestimonialSlider from '../components/testimonial-slider/1';
import NewsSection from '../components/news-section/1';
import HomeSlider2 from '../components/home-slider/2';
import NoticeSection from '../components/notice-section/1';
import GoogleMap from '../components/map/1';
import Popup1 from '../components/popup/1';
import useHome from '../hooks/use-home';

const Home = () => {
  const {home} = useHome();

  return (
    <div className="main">
      <HomeSlider2 {...home.sliders} />
      <Introduction />
      <CounterBlock />
      <NewsSection {...home.posts} />
      <NoticeSection {...home.notices} />
      <TestimonialSlider {...home.testimonials} />
      <GoogleMap />
      {home.popup.data.map((popup) => (
        <Popup1
          key={popup.id}
          popup={popup}
          resourcePath={home.popup.resourcePath}
        />
      ))}
    </div>
  );
};

export default Home;
