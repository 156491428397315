import React, {useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import BannerImage from '../components/banner-image/1';
import useAbout from '../hooks/use-about';
import {Navigate, useParams} from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import {Helmet, HelmetProvider} from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  detailPageBg: {
    padding: '90px 0 50px',
  },
  detailPageMain: {
    '& .content-holder': {
      position: 'relative',
      '& .detail': {
        '& .detail-title': {
          fontSize: '32px',
          color: '#434343',
          fontWeight: 700,
          marginBottom: '20px',
        },
        '& .img-holder': {
          marginBottom: '30px',
          '& img': {
            height: 'auto',
            width: '100%',
          },
        },
        '& .description': {
          lineHeight: 1.7,
          fontSize: '18px',
          color: '#73738c',
          '& p': {
            '& strong': {
              color: '#434343',
            },
            '&:not(:last-child)': {
              marginBottom: '15px',
            },
            '& img': {
              width: 'auto',
              objectFit: 'contain',
            },
            '& .btn-outline-primary': {
              color: '#fff',
              borderRadius: '10px',
              background: '#009f86',
              boxShadow: `0 10px 50px #009f864f`,
              transition: 'all .3s ease-out',
              border: 'none',
              padding: '8px 20px',
              textDecoration: 'none',
              '&:hover': {
                background: '#219ebc',
                boxShadow: `0 10px 50px #219ebc4f`,
              },
            },
          },
          '& img': {
            width: '100% !important',
            objectFit: 'contain',
          },
          '& h1, h2, h3, h4, h5, h6': {
            color: '#009f86',
          },
          '& table': {
            border: 'none',
            marginTop: '20px',
            '& tbody': {
              '& tr': {
                '& th': {
                  padding: '10px',
                  background: '#009f86',
                  color: '#fff',
                  border: `1px solid red`,
                  '&:not(:last-child)': {
                    borderRight: '1px solid #73738c',
                    borderBottom: 'none',
                  },
                },
                '& td': {
                  padding: '10px',
                  border: '1px solid #73738c',
                },
              },
            },
          },
        },
        '& .button': {
          display: 'inline-block',
          '& .btn-outline-danger': {
            background: '#219ebc',
            color: '#fff',
            borderRadius: '10px',
            border: 'none',
            boxShadow: `0 10px 50px #219ebc4f`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all .3s ease-out',
            '& .MuiSvgIcon-root': {
              marginLeft: '5px',
              fontSize: '22px',
            },
            '&:hover': {
              background: '#009f86',
              boxShadow: `0 10px 50px #009f864f`,
            },
          },
        },
      },
    },
  },
}));

const AboutDetail = () => {
  const {slug} = useParams();
  const {post, isLoading} = useAbout(slug);

  useEffect(() => {
    if (!isLoading && !post) {
      Navigate('/404');
    }
  }, [isLoading, post]);

  const classes = useStyles();
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="description" content={post?.meta_description} />
        <meta name="keywords" content={post?.meta_keywords} />
        <meta property="og:description" content={post?.meta_description} />
      </Helmet>
      <BannerImage baseMenu={{title: 'About'}} title="Introduction" />
      <section className={`${classes.detailPageBg}`}>
        <div className={`container-fluid ${classes.detailPageMain}`}>
          <div className="content-holder">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {post ? (
                  <div className="detail">
                    <div className="detail-content-page">
                      {post.image ? (
                        <div className="img-holder">
                          <img
                            src={post.image}
                            alt={post.title}
                            className="img img-fluid"
                          />
                        </div>
                      ) : null}

                      <div className="description">
                        {HTMLReactParser(post.content || '')}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default AboutDetail;
