import {createTheme} from '@mui/material/styles';

const generateTheme = (profile) => {
  return createTheme({
    palette: {
      primary: {
        main: profile.primary,
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: profile.secondary,
        contrastText: '#024664',
      },
    },
    siteName: profile.siteName,
  });
};

export default generateTheme;
