import React from 'react';
import {makeStyles} from '@mui/styles';
import Fade from 'react-reveal';
import usePosts from '../hooks/use-posts';
import {AVS, MODULES} from '../utils/constants';
import BannerImage from '../components/banner-image/1';
import {useSWRConfig} from 'swr';
import {useSearchParams} from 'react-router-dom';
import Pagination1 from '../components/pagination/1';

const useStyles = makeStyles((theme) => ({
  testimonialListBg: {
    position: 'relative',
    padding: '90px 0 50px',
  },
  testimonialListMain: {
    '& .content-holder': {
      '& .text-holder': {
        marginBottom: '50px',
        textAlign: 'center',
        '& .title': {
          fontSize: '32px',
          fontWeight: '700',
          color: '#434343',
        },
      },
      '& .marginBottom': {
        marginBottom: '30px',
        '& .testimonial-list': {
          background: '#fff',
          borderRadius: '15px',
          boxShadow: '0 5px 50px rgb(156 185 226 / 20%)',
          padding: '20px',
          height: '100%',
          textAlign: 'center',
          '& .img-holder': {
            height: '100px',
            width: '100px',
            margin: '0 auto 20px auto',
            '& .bg-stretch': {
              height: '100px',
              width: '100px',
              borderRadius: '50%',
              backgroundPosition: 'top',
            },
          },
          '& .content': {
            '& .name': {
              fontSize: '22px',
              fontWeight: '700',
              color: theme.palette.primary.main,
            },
            '& .position': {
              fontSize: '18px',
              color: '#434343',
            },
            '& .description': {
              fontSize: '16px',
              color: '#73738c',
              marginBottom: 0,
              lineHeight: '1.7',
            },
          },
        },
      },
    },
  },
}));

const TestimonialList = () => {
  const [searchParams] = useSearchParams();
  const {
    posts: testimonials,
    resourcePath,
    currentPage,
    lastPage,
  } = usePosts(MODULES.TESTIMONIAL, searchParams.get('page'));
  const {siteName} = useSWRConfig();
  const classes = useStyles();

  return (
    <>
      <BannerImage
        baseMenu={{title: 'People'}}
        title={siteName === AVS ? 'Teachers and Staff' : 'Our Testimonials'}
      />
      <section className={`${classes.testimonialListBg}`}>
        <div className={`container-fluid ${classes.testimonialListMain}`}>
          <div className="content-holder">
            <div className="row">
              {testimonials.map((item, i) => {
                return (
                  <Fade bottom duration={2000} key={i + '___'}>
                    <div className="col-lg-4 col-md-12 col-sm-12 marginBottom">
                      <div className="testimonial-list">
                        <div className="img-holder">
                          <div
                            className="bg-stretch background-image"
                            style={{
                              backgroundImage: `url('${resourcePath.replace(
                                'testimo$testimonials',
                                'testimonials'
                              )}/${item.image}')`,
                            }}
                          />
                        </div>
                        <div className="content">
                          <h3 className="name">{item.name}</h3>
                          <h4 className="position">{item.job_title}</h4>
                          <p className="description">{item.message}</p>
                        </div>
                      </div>
                    </div>
                  </Fade>
                );
              })}
            </div>

            <Pagination1 currentPage={currentPage} lastPage={lastPage} />
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialList;
