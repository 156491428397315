import React from 'react';
import {makeStyles} from '@mui/styles';
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Fade from 'react-reveal';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const useStyles = makeStyles((theme) => ({
  counterBlockBg: {
    padding: '50px',
    background: theme.palette.secondary.main,
    borderRadius: '15px',
    position: 'relative',
    zIndex: '1',
  },
  '@media(max-width: 600px)': {
    counterBlockBg: {
      marginBottom: '30px',
    },
  },
  '@media(min-width: 768px)': {
    counterBlockBg: {
      marginBottom: '30px',
    },
  },
  '@media(min-width: 992px)': {
    counterBlockBg: {
      marginBottom: '-115px',
    },
  },

  counterBlockMain: {
    '& .content-holder': {
      '& .marginBottom': {
        position: 'relative',
        '&:not(:last-child)': {
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translate(0, -50%)',
            width: '1px',
            height: '100%',
            background: '#fff',
          },
        },
        '& .counter-list': {
          textAlign: 'center',
          '& .icon-holder': {
            marginBottom: '10px',
            '& .MuiSvgIcon-root': {
              fontSize: '42px',
              color: '#fff',
            },
          },
          '& .value': {
            height: '50px',
            fontSize: '32px',
            color: '#fff',
            fontWeight: 700,
            marginBottom: '10px',
          },
          '& .title': {
            fontSize: '18px',
            color: '#fff',
            fontWeight: 700,
          },
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '&:not(:last-child)': {
            marginBottom: '30px',
            '&::after': {
              display: 'none',
            },
          },
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '&:not(:last-child)': {
            marginBottom: '30px',
            '&::after': {
              display: 'none',
            },
          },
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '&:not(:last-child)': {
            marginBottom: '0',
            '&::after': {
              display: 'block',
            },
          },
        },
      },
    },
  },
}));
const statstics = [
  {
    title: 'STUDENTS ENROLLED',
    value: '500+',
    icon: <BackpackOutlinedIcon />,
  },
  {
    title: 'SATISFACTION RATE',
    value: '100%',
    icon: <EmojiEventsOutlinedIcon />,
  },
  {
    title: 'ACADEMIC PROGRAMS',
    value: '5+',
    icon: <MenuBookOutlinedIcon />,
  },
  {
    title: 'INSTRUCTORS',
    value: '50+',
    icon: <SchoolOutlinedIcon />,
  },
];
const CounterBlock = () => {
  const classes = useStyles();
  return (
    <section className={`container-fluid ${classes.counterBlockBg}`}>
      <div className={`${classes.counterBlockMain}`}>
        <div className="content-holder">
          <div className="row">
            {statstics.map((item, i) => {
              const lastItem = item.value.slice(item.value.length - 1) + "";
              const addElement = (lastItem === "%" || lastItem === "+") ? lastItem : "";
              const figure = item.value.replace("+", "").replace("%", "");
              return (
                <Fade bottom duration={2000} key={i + '___'}>
                  <div className="col-lg-3 col-md-12 col-sm-12 marginBottom">
                    <div className="counter-list">
                      <div className="icon-holder">{item.icon}</div>
                      <VisibilitySensor partialVisibility offset={{bottom: 50}}>
                        {({isVisible}) => (
                            <div className="value">
                              {isVisible ? <CountUp end={Number(figure)}/> : null}
                              <span className={addElement}>{addElement}</span>
                            </div>
                        )}
                      </VisibilitySensor>
                      <h3 className="title">{item.title}</h3>
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterBlock;
