import React, {useEffect} from 'react';
import {Link, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import usePosts from '../hooks/use-posts';
import BannerImage from '../components/banner-image/1';
import Pagination1 from '../components/pagination/1';

const useStyles = makeStyles((theme) => ({
  listPageBg: {
    padding: '90px 0 50px',
    background: '#f3f4f8',
  },
  listPageMain: {
    '& .content-holder': {
      '&>.text-holder': {
        marginBottom: '50px',
        textAlign: 'center',
        '& .title': {
          fontSize: '32px',
          fontWeight: '700',
          color: '#434343',
        },
      },
      '& .marginBottom': {
        marginBottom: '30px',
        '& .page-list': {
          background: '#fff',
          borderRadius: '15px',
          padding: '20px',
          boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          '& .img-holder': {
            height: '180px',
            width: '180px',
            '& .bg-stretch': {
              height: '180px',
              width: '180px',
              borderRadius: '15px',
            },
          },
          '& .content': {
            width: '100%',
            '& .text-holder': {
              '& .list-title': {
                marginBottom: '10px',
                '& a': {
                  color: '#009f86',
                  fontSize: '20px',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'all .3s ease-out',
                  '&:hover': {
                    color: '#219ebc',
                  },
                },
              },
              '& .description': {
                color: '#73738c',
                marginBottom: '10px',
                padding: 0,
                fontSize: '16px',
              },
              '& .button': {
                '& a': {
                  textDecoration: 'none',
                  color: '#434343',
                  fontWeight: 500,
                  fontSize: '16px',
                  transition: 'all .3s ease-out',
                  '& .MuiSvgIcon-root': {
                    fontSize: '18px',
                    marginLeft: '5px',
                  },
                  '&:hover': {
                    color: '#219ebc',
                  },
                },
              },
            },
            '& .date': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '15px',
              '& .MuiSvgIcon-root': {
                fontSize: '18px',
                marginRight: '5px',
                color: '#73738c',
              },
              '& .month': {
                fontSize: '14px',
                color: '#73738c',
                display: 'flex',
                alignItems: 'center',
              },
              '& .time': {
                fontSize: '14px',
                color: '#73738c',
                display: 'flex',
                alignItems: 'center',
              },
            },
          },
        },
      },
    },
    '@media(max-width:600px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '& .page-list': {
            flexWrap: 'wrap',
            justifyContent: 'center',
            textAlign: 'center',
            '& .img-holder': {
              marginBottom: '20px',
            },
          },
        },
      },
    },
    '@media(min-width:768px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '& .page-list': {
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            textAlign: 'left',
            '& .img-holder': {
              marginRight: '20px',
              marginBottom: '0',
            },
          },
        },
      },
    },
    '@media(min-width:992px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '& .page-list': {
            flexWrap: 'nowrap',
            textAlign: 'left',
            justifyContent: 'flex-start',
            '& .img-holder': {
              marginRight: '20px',
              marginBottom: '0',
            },
          },
        },
      },
    },
  },
}));

const List = () => {
  const {module} = useParams();
  const [searchParams] = useSearchParams();
  const isPost = module === 'kids-corner' || module === 'blogs';
  const {posts, resourcePath, isLoading, currentPage, lastPage} = usePosts(
    isPost ? 'posts' : module,
    searchParams.get('page')
  );
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    if (!isPost && module !== 'notices') {
      navigate('/404');
    }
  }, [isPost, module, navigate]);

  return (
    <>
      <BannerImage
        title={
          module === 'kids-corner'
            ? 'Kids Corner'
            : `Our ${module.charAt(0).toUpperCase() + module.slice(1)}`
        }
      />
      <section className={`${classes.listPageBg}`}>
        <div className={`container-fluid ${classes.listPageMain}`}>
          <div className="content-holder">
            {!isLoading && posts.length === 0 ? (
              <div className="text-center">
                Sorry, there are no {module} available at the moment.
              </div>
            ) : null}
            <div className="row">
              {posts.map((item, i) => {
                return (
                  <div
                    className="col-lg-6 col-md-12 col-sm-12 marginBottom"
                    key={i++}
                  >
                    <div className="page-list">
                      <div className="img-holder">
                        <Link to={`/${item.slug}`}>
                          <div
                            className="bg-stretch background-image"
                            style={{
                              backgroundImage: `url('${resourcePath}/${item.image}')`,
                            }}
                          />
                        </Link>
                      </div>
                      <div className="content">
                        <div className="text-holder">
                          <div className="list-title">
                            <Link to={`/${module}/${item.slug}`}>
                              {item.title.length > 50
                                ? item.title.substring(0, 50) + '...'
                                : item.title}
                            </Link>
                          </div>
                          {/* <div className="date">
                          <div className="month">
                            <DateRangeRoundedIcon />
                            {dayjs().format('D MMMM YYYY')}
                          </div>
                          <div className="time">
                            <AccessTimeRoundedIcon />
                            {dayjs().format('h:mm:ss a')}
                          </div>
                        </div> */}
                          <div className="description">
                            {item.summary.length > 50
                              ? item.summary.substring(0, 50) + '...'
                              : item.summary}
                          </div>
                          <div className="button">
                            <Link to={`/${module}/${item.slug}`}>
                              Read More
                              <ArrowForwardRoundedIcon />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <Pagination1 currentPage={currentPage} lastPage={lastPage} />
        </div>
      </section>
    </>
  );
};

export default List;
