import * as yup from 'yup';

let contactSchema = yup.object().shape({
  name: yup.string().required('Name Is Required'),
  email: yup.string().email('Enter Valid Email').required('Email Is Required'),
  phone: yup.number().required('Phone Number Is Required'),
  subject: yup.string().required('Subject Is Required'),
  message: yup.string().required('Message Is Required'),
});

let admissionSchema = yup.object().shape({
  name: yup.string().required('Name Is Required'),
  email: yup.string().email('Enter Valid Email').required('Email Is Required'),
  phone: yup.number().required('Phone Number Is Required'),
  address: yup.string().required('Address Is Required'),
  gradeId: yup.string().nullable(),
  formType: yup.string().nullable(),
  message: yup.string().required('Message Is Required'),
});

// eslint-disable-next-line import/no-anonymous-default-export
export {contactSchema, admissionSchema};
