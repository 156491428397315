import React, {useState} from 'react';
import {contactSchema} from '../../../../functions/validation';
import {makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Button} from '@mui/material';
import {Formik} from 'formik';
import Loader from '../../../loader/1';
import toast from '../../../notification';
import axios from 'axios';
import {useSWRConfig} from 'swr';
import {ToastContainer} from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  contactFormBg: {},
  contactFormMain: {
    '& .form-divider': {
      '& .form-list': {
        '&:last-child': {
          width: '100%',
          paddingRight: '0',
        },
        '& .MuiTextField-root': {
          width: '100%',
          margin: 0,
        },
        '& .MuiInputLabel-root': {
          fontFamily: 'unset',
          fontSize: '16px',
          letterSpacing: '1px',
          color: '#434343',
          fontWeight: 500,
        },
        '& .MuiInput-root': {
          fontFamily: 'unset',
          color: theme.palette.primary.main,
          '&::before': {
            borderBottom: '1px solid #73738c',
          },
          '&::after': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
        },
        '& .error-message': {
          margin: 0,
          color: 'red',
          marginTop: '5px',
        },
      },
    },
    '& .MuiButton-outlined': {
      position: 'relative',
      border: `2px solid #009f86`,
      boxShadow: `0 10px 50px #009f864f`,
      fontSize: '16px',
      overflow: 'hidden',
      transition: 'all .3s ease-out',
      zIndex: 1,
      color: '#009f86',
      '&::before': {
        content: "''",
        zIndex: -1,
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '1em',
        height: '1em',
        borderRadius: '50%',
        background: '#219ebc',
        transformOrigin: 'center',
        transform: 'translate3d(-50%, -50%, 0) scale3d(0, 0, 0)',
        transition: 'transform .3s ease-out',
      },
      '& .fa': {
        marginLeft: '10px',
        fontSize: '18px',
      },
      '&:hover': {
        color: '#fff',
        border: `2px solid #219ebc`,
        boxShadow: `0 10px 50px #219ebc20`,
        '&::before': {
          transform: 'translate3d(-50%, -50%, 0) scale3d(15, 15, 15)',
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .form-divider': {
        '& .form-list': {
          marginBottom: '30px',
          width: '100%',
          '&:nth-child(odd)': {
            paddingRight: '0',
          },
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .form-divider': {
        '& .form-list': {
          marginBottom: '30px',
          width: '100%',
          '&:nth-child(odd)': {
            paddingRight: '0',
          },
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .form-divider': {
        '& .form-list': {
          marginBottom: '35px',
        },
      },
    },
  },
}));

const ContactForm = () => {
  const [value, setValue] = useState('Controlled');
  const [eventTarget, setEventTarget] = useState();
  const [submittingForm, setSubmittingForm] = useState(false);
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  let url = `${useSWRConfig().apiUrl}/contact-us`;

  const handleSubmit = (values, {resetForm}) => {
    setSubmittingForm(true);
    axios.post(url, values).then(
      (res) => {
        setSubmittingForm(false);
        resetForm({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        });
        toast.showSuccess(`Message Sent SuccessFully`);
      },
      (error) => {
        toast.showError('Sent Faild');
        setSubmittingForm(false);
      }
    );
  };
  return (
    <>
      <section className={`${classes.contactFormBg}`}>
        <div className={`${classes.contactFormMain}`}>
          {submittingForm ? (
            <div
              className="full-page-loader d-flex justify-content-center align-items-center"
              style={{height: '70vh'}}
            >
              <Loader />
            </div>
          ) : (
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
              }}
              validationSchema={contactSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <>
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={(event) => {
                      handleSubmit(event);
                      setEventTarget(event.target);
                    }}
                  >
                    <div className="form-divider">
                      <div className="form-list">
                        <TextField
                          id="standard-textarea"
                          label="Full Name"
                          name="name"
                          type="text"
                          placeholder="Enter Name"
                          variant="standard"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name ? (
                          <div className="error-message">{errors.name}</div>
                        ) : null}
                      </div>
                      <div className="form-list">
                        <TextField
                          id="standard-textarea"
                          label="Email"
                          name="email"
                          type="email"
                          placeholder="Enter Email"
                          variant="standard"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <div className="error-message">{errors.email}</div>
                        ) : null}
                      </div>
                      <div className="form-list">
                        <TextField
                          id="standard-textarea"
                          label="Phone"
                          name="phone"
                          placeholder="Enter Phone Number"
                          type="number"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                        {errors.phone && touched.phone ? (
                          <div className="error-message">{errors.phone}</div>
                        ) : null}
                      </div>
                      <div className="form-list">
                        <TextField
                          id="standard-textarea"
                          label="Subject"
                          name="subject"
                          placeholder="Enter Subject"
                          type="text"
                          variant="standard"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={value.subject}
                        />
                        {errors.subject && touched.subject ? (
                          <div className="error-message">{errors.subject}</div>
                        ) : null}
                      </div>
                      <div className="form-list">
                        <TextField
                          id="standard-textarea"
                          label="Message"
                          name="message"
                          placeholder="Enter Message"
                          type="text"
                          multiline
                          variant="standard"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                        />
                        {errors.message && touched.message ? (
                          <div className="error-message">{errors.message}</div>
                        ) : null}
                      </div>
                    </div>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="rounded-pill"
                      type="submit"
                    >
                      Send Message <i className="fa fa-paper-plane-o" />
                    </Button>
                  </Box>
                  <ToastContainer position="top-center" />
                </>
              )}
            </Formik>
          )}
        </div>
      </section>
    </>
  );
};

export default ContactForm;
