import React from 'react';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import {Button} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles((theme) => ({
  detailPageBg: {
    padding: '200px 0 100px 0',
  },
  detailPageMain: {
    '& .content-holder': {
      position: 'relative',
      textAlign: 'center',
      '& .detail': {
        '& .detail-title': {
          fontSize: '32px',
          color: '#434343',
          fontWeight: 700,
          marginBottom: '20px',
        },
        '& .description': {
          lineHeight: 1.7,
          fontSize: '18px',
          color: '#73738c',
          '& p': {
            '& strong': {
              color: '#434343',
            },
            '&:not(:last-child)': {
              marginBottom: '15px',
            },
            '& img': {
              width: 'auto',
              objectFit: 'contain',
            },
            '& .btn-outline-primary': {
              color: '#fff',
              borderRadius: '10px',
              background: '#009f86',
              boxShadow: `0 10px 50px #009f864f`,
              transition: 'all .3s ease-out',
              border: 'none',
              padding: '8px 20px',
              textDecoration: 'none',
              '&:hover': {
                background: '#219ebc',
                boxShadow: `0 10px 50px #219ebc4f`,
              },
            },
          },
          '& .MuiButton-root': {
            background: theme.palette.primary.main,
            borderRadius: '8px',
            boxShadow: '0px 10px 50px #009f864f',
            textTransform: 'capitalize',
            transition: 'all 0.3s ease-out',
            '& .MuiButton-endIcon': {
              marginLeft: '2px',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '16px',
              marginLeft: '0',
            },
            '&:hover': {
              background: '#0077b6',
              color: '#fff',
            },
          },
        },
      },
    },
  },
}));

const NotFound1 = () => {
  const classes = useStyles();
  return (
    <section className={`${classes.detailPageBg}`}>
      <div className={`container-fluid ${classes.detailPageMain}`}>
        <div className="content-holder">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="detail">
                <h2 className="detail-title">Oops! Page Not Found.</h2>
                <div className="description">
                  <p>
                    The page you are looking for is not available or has been
                    moved.
                  </p>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/"
                    startIcon={<ArrowBack />}
                  >
                    Go Back to Home
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound1;
