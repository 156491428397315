export const SHREEKUL = 'shreekul';
export const AVS = 'avs';

export const MODULES = {
  NOTICE: 'notices',
  SLIDER: 'sliders',
  GALLERY: 'gallery',
  VIDEO: 'videos',
  POST: 'posts',
  TESTIMONIAL: 'testimonials',
  TEAM: 'teams',
  DOWNLOAD: 'downloads',
};

export const SHREEKUL_MENU = [
  {
    name: 'Home',
    slug: '',
  },
  {
    name: 'Notice',
    slug: 'notices',
  },
  {
    name: 'Blog',
    slug: 'blogs',
  },
  {
    name: 'Calendar',
    slug: 'calendar',
  },
  {
    name: 'Downloads',
    slug: 'downloads',
  },
  {
    name: 'Gallery',
    subMenu: [
      {
        name: 'Photo Gallery',
        slug: 'gallery/photo',
      },
      {
        name: 'Video Gallery',
        slug: 'gallery/video',
      },
    ],
  },
  {
    name: 'Contact',
    slug: 'contact-us',
  },
];

export const AVS_MENU = [
  {
    name: 'Home',
    slug: '',
  },
  {
    name: 'About',
    slug: '/about',
  },
  {
    name: 'People',
    subMenu: [
      {
        name: 'Board of Directors',
        slug: 'people/board-of-directors',
      },
      {
        name: 'Teachers & Staff',
        slug: 'people/teachers-and-staff',
      },
    ],
  },
  {
    name: 'Notice',
    slug: 'notices',
  },
  {
    name: 'Activities',
    slug: 'activities',
  },
  {
    name: 'Kids Corner',
    slug: 'kids-corner',
  },
  {
    name: 'Gallery',
    subMenu: [
      {
        name: 'Photo Gallery',
        slug: 'gallery/photo',
      },
      {
        name: 'Video Gallery',
        slug: 'gallery/video',
      },
    ],
  },
  {
    name: 'Contact',
    slug: 'contact-us',
  },
];
