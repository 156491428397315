import React, {useContext} from 'react';
import {makeStyles} from '@mui/styles';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermPhoneMsgOutlinedIcon from '@mui/icons-material/PermPhoneMsgOutlined';
import ContactForm from '../contact-form/1';
import WebsiteContext from '../../../contexts/website-context';
import BannerImage from '../../banner-image/1';

const useStyles = makeStyles((theme) => ({
  contactBg: {
    padding: '90px 0 50px',
    background: '#f9fcff',
  },
  contactMain: {
    '& .text-holder': {
      marginBottom: '50px',
      '& .title': {
        textAlign: 'center',
        fontSize: '42px',
        fontWeight: 700,
        letterSpacing: '2px',
        position: 'relative',
        margin: 0,
        paddingBottom: '10px',
        '&::before': {
          content: "''",
          position: 'absolute',
          bottom: 0,
          width: '130px',
          height: '2px',
          background: '#009f86',
        },
        '&::after': {
          content: "''",
          position: 'absolute',
          left: '50%',
          width: '0',
          height: '0',
          bottom: '-9px',
          borderTop: '10px solid transparent',
          borderLeft: `10px solid #009f86`,
          borderBottom: '10px solid transparent',
        },
      },
    },
    '& .content-holder': {
      display: 'flex',
      '& .contact-info-holder': {
        background: '#fff',
        boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
        padding: '30px 20px',
        borderRadius: '15px',
        '& .contact-info': {
          marginBottom: '30px',
          '& ul': {
            margin: 0,
            padding: 0,
            '& li': {
              listStyle: 'none',
              '&:not(:last-child)': {
                marginBottom: '20px',
              },
              '& .divider': {
                display: 'flex',
                alignItems: 'center',
                '& .icon-holder': {
                  height: '40px',
                  width: '40px',
                  background: `#009f864f`,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: `#009f864f 8px 8px 30px 0px`,
                  marginRight: '10px',
                  '& .MuiSvgIcon-root': {
                    color: '#009f86',
                  },
                },
                '& .text': {
                  '& .address-title': {
                    fontSize: '14px',
                    color: '#009f86',
                    margin: 0,
                    fontWeight: 500,
                  },
                  '& a': {
                    textDecoration: 'none',
                    fontSize: '14px',
                    color: '#219ebc',
                    transition: 'all .3s ease-out',
                    '&:hover': {
                      color: '#009f86',
                    },
                  },
                },
              },
            },
          },
        },
        '& .map-holder': {
          display: 'inline-block',
          '& iframe': {
            height: '260px',
            borderRadius: '15px',
          },
        },
      },
      '& .form-holder': {
        background: '#fff',
        boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
        padding: '20px',
        borderRadius: '15px',
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        flexWrap: 'wrap',
        '& .contact-info-holder': {
          width: '100%',
          marginBottom: '30px',
          '& .map-holder': {
            '& iframe': {
              width: '100%',
            },
          },
        },
        '& .form-holder': {
          width: '100%',
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        flexWrap: 'wrap',
        '& .contact-info-holder': {
          width: '100%',
          marginBottom: '30px',
          '& .map-holder': {
            '& iframe': {
              width: '700px',
            },
          },
        },
        '& .form-holder': {
          width: '100%',
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        flexWrap: 'nowrap',
        '& .contact-info-holder': {
          width: '50%',
          marginRight: '50px',
          marginBottom: 0,
          '& .map-holder': {
            '& iframe': {
              width: '500px',
            },
          },
        },
        '& .form-holder': {
          width: '50%',
        },
      },
    },
  },
}));

const Contact = () => {
  const {profile} = useContext(WebsiteContext);
  const classes = useStyles();
  return (
    <>
      <BannerImage title="Contact" />
      <section className={`${classes.contactBg}`}>
        <div className={`${classes.contactMain} container-fluid`}>
          <div className="text-holder">
            <h2 className="title">Get In Touch</h2>
          </div>
          <div className="content-holder">
            <div className="contact-info-holder">
              <div className="contact-info">
                <ul>
                  <li>
                    <div className="divider">
                      <div className="icon-holder">
                        <LocationOnOutlinedIcon />
                      </div>
                      <div className="text">
                        <h6 className="address-title">Location</h6>
                        <a
                          href={`http://maps.google.com/?q=${profile.address}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {profile.address}
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="divider">
                      <div className="icon-holder">
                        <EmailOutlinedIcon />
                      </div>
                      <div className="text">
                        <h6 className="address-title">Email:</h6>
                        <a href={`mailto:${profile.email}`}>
                          <span>{profile.email}</span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="divider">
                      <div className="icon-holder">
                        <PermPhoneMsgOutlinedIcon />
                      </div>
                      <div className="text">
                        <h6 className="address-title">Phone:</h6>
                        <a href={`tel:${profile.phone}`}>
                          <span>{profile.phone}</span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="map-holder">
                <iframe
                  title={`${profile.title} Map`}
                  src={profile.googleMap}
                  width="600"
                  style={{border: 0}}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
            <div className="form-holder">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
