import axios from 'axios';
import {useSWRConfig} from 'swr';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url) => axios.get(url);

export default function usePosts(module, page = 1, slug = null) {
  let queryUrl = '';

  if (!isNaN(page) && page > 1) {
    queryUrl = `?page=${page}`;
  }

  const url = `${useSWRConfig().apiUrl}/${module}${queryUrl}`;
  const {data, error} = useSWRImmutable(url, fetcher);

  let postData = [];
  let resourcePath = null;
  let posts;
  let currentPage;
  let lastPage;

  const postInfo = data?.data?.data[module];
  posts = postInfo?.data;
  currentPage = postInfo?.current_page;
  lastPage = postInfo?.last_page;

  if (posts) {
    let tempPosts = {...posts};
    resourcePath = tempPosts?.image_path;

    if (resourcePath) {
      delete tempPosts.image_path;
    }

    postData = Object.values(tempPosts);

    if (slug && postData.length > 1) {
      let isId = !isNaN(slug);
      let postKey = isId ? 'id' : 'slug';
      postData = postData.filter((post) => post[postKey] == slug);
    }
  }

  return {
    posts: postData,
    resourcePath: resourcePath,
    isLoading: !error && !data,
    isError: error,
    currentPage: currentPage,
    lastPage: lastPage,
  };
}
