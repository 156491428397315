import React, {useContext} from 'react';
import WebsiteContext from '../../../contexts/website-context';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  googleMapMain: {
    '& .content-holder': {
      '& .map': {
        display: 'flex',
        '& iframe': {
          width: '100%',
          height: 500,
          borderRadius: 0,
        },
      },
    },
  },
}));

const GoogleMap = () => {
  const {profile} = useContext(WebsiteContext);
  const classes = useStyles();
  return (
    <section className={`${classes.googleMapMain}`}>
      <div className="content-holder">
        <div className="map">
          <iframe
            title={`${profile.title} Map`}
            src={profile.googleMap}
            width="100%"
            style={{border: 0}}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </section>
  );
};

export default GoogleMap;
