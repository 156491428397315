import React, {useContext} from 'react';
import {makeStyles} from '@mui/styles';
import BreadCrumb from '../../breadcrumb/1';
import WebsiteContext from '../../../contexts/website-context';
import {SHREEKUL} from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  bannerImageBg: {
    marginTop: theme.siteName === SHREEKUL ? '84px' : '',
    padding: '50px 0',
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.4)',
    },
  },
  '@media(max-width: 600px)': {
    bannerImageBg: {
      height: '35vh',
    },
  },
  '@media(min-width: 768px)': {
    bannerImageBg: {
      height: '40vh',
    },
  },
  '@media(min-width: 992px)': {
    bannerImageBg: {
      height: '45vh',
    },
  },

  bannerImageMain: {
    position: 'relative',
    '& .content-holder': {
      '& .text-holder': {
        '& .title': {
          fontWeight: 700,
          color: '#fff',
        },
      },
    },
  },
}));

const BannerImage = ({title, baseMenu, image}) => {
  const classes = useStyles();
  const {profile} = useContext(WebsiteContext);
  return (
    <section
      className={`background-image ${classes.bannerImageBg}`}
      style={{backgroundImage: `url('${image ?? profile.bannerImage}')`}}
    >
      <div className={`container-fluid ${classes.bannerImageMain}`}>
        <div className="content-holder">
          <div className="text-holder">
            <h2 className="title">{title}</h2>
          </div>
          <BreadCrumb title={title} baseMenu={baseMenu} />
        </div>
      </div>
    </section>
  );
};
export default BannerImage;
