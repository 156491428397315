import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';
import {SWRConfig} from 'swr';
import useLayout from './hooks/use-layout';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from './components/loader/1';
import 'react-toastify/dist/ReactToastify.css';
import {SHREEKUL, AVS} from './utils/constants';

const Index = () => {
  const {layout, isLoading} = useLayout();
  return isLoading ? <Loader /> : <App website={layout} />;
};

const siteName = SHREEKUL;
const apiUrl = `https://${
  siteName === SHREEKUL ? 'shreekulschool.edu.np' : 'avsschool.edu.np'
}/api`;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <SWRConfig
    value={{
      apiUrl: apiUrl,
      siteName: siteName,
      onError: (error, key) => {
        console.log(error);
        if (error.status !== 403 && error.status !== 404) {
          // We can send the error to Sentry,
          // or show a notification UI.
          console.log(error);
          // showError('Sorry! Something went wrong.');
        }
      },
    }}
  >
    <Index />
  </SWRConfig>
  // </React.StrictMode>
);
reportWebVitals();
