import React from 'react';
import Fade from 'react-reveal/Fade';
import {makeStyles} from '@mui/styles';
import BannerImage from '../components/banner-image/1';
import useGallery from '../hooks/use-gallery';

// style
const useStyles = makeStyles((theme) => ({
  galleryListBg: {
    padding: '90px 0 50px',
  },
  galleryListMain: {
    '& .content-holder': {
      '& .text-holder': {
        marginBottom: '50px',
        textAlign: 'center',
        '& .title': {
          fontSize: '32px',
          fontWeight: '700',
          color: '#434343',
        },
      },
      '& .marginBottom': {
        marginBottom: '30px',
        '& .box': {
          position: 'relative',
          boxShadow: `0 10px 50px rgb(166 209 237 / 20%)`,
          overflow: 'hidden',
          borderRadius: '15px',
          '& .img-holder': {
            height: '250px',
            width: '100%',
            '& .bg-stretch': {
              height: '100%',
              width: '100%',
              transform: 'scale(1)',
              transition: 'all 0.5s',
              position: 'relative',
            },
            '& .content': {
              textAlign: 'center',
              width: '100%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              left: '50%',
              top: '50%',
              zIndex: 3,
              padding: '20px',
              '& .title': {
                color: '#fff',
                fontSize: '18px',
                fontWeight: 600,
                textTransform: 'capitalize',
                margin: '0 0 5px',
                transform: 'rotateY(360deg)',
                transition: 'all 0.5s',
              },
            },
          },
        },
      },
    },
  },
}));

const VideoList = () => {
  const {posts: videos} = useGallery('video');
  const classes = useStyles();
  return (
    <>
      <BannerImage baseMenu={{title: 'Gallery'}} title="Video Gallery" />
      <section className={`${classes.galleryListBg}`}>
        <div className={`container-fluid ${classes.galleryListMain}`}>
          <div className="content-holder">
            {/* <div className="text-holder">
              <h2 className="title">Video Gallery</h2>
            </div> */}
            <div className="row">
              {videos.map((item, i) => {
                let video_id = item.link.split('v=')[1];
                let ampPos = video_id.indexOf('&');
                if (ampPos !== -1) {
                  video_id = video_id.substring(0, ampPos);
                }

                return (
                  <div
                    className="col-lg-4 col-md-12 col-sm-12 marginBottom"
                    key={i + '__'}
                  >
                    <Fade bottom duration={2000}>
                      <div className="box">
                        <div className="img-holder">
                          <div className="bg-stretch background-image">
                            <iframe
                              width="100%"
                              height="250"
                              src={`https://www.youtube.com/embed/${video_id}?showinfo=0&controls=0&autohide=1`}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                          {/* <div className="content">
                          <h3 className="title">{item.title}</h3>
                        </div> */}
                        </div>
                      </div>
                    </Fade>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoList;
