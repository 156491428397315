import axios from 'axios';
import {useSWRConfig} from 'swr';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url) => axios.get(url);

export default function useCalendar() {
  const url = `${useSWRConfig().apiUrl}/calendar`;
  const {data, error} = useSWRImmutable(url, fetcher);

  let postData = [];
  let resourcePath = null;
  let posts = data?.data?.data.calendar;

  if (posts) {
    let tempPosts = {...posts};
    resourcePath = posts.image_path;
    delete tempPosts.image_path;
    postData = Object.values(tempPosts);
    postData.sort((a, b) => a.month - b.month);
  }

  return {
    posts: postData,
    resourcePath: resourcePath,
    isLoading: !error && !data,
    isError: error,
  };
}
