import React, {useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import BannerImage from '../components/banner-image/1';
import HTMLReactParser from 'html-react-parser';
import usePosts from '../hooks/use-posts';
import {Pagination} from 'react-bootstrap';
import {Link, useSearchParams} from 'react-router-dom';
import {MODULES} from '../utils/constants';

const useStyles = makeStyles((theme) => ({
  detailPageBg: {
    padding: '90px 0 50px',
  },
  detailPageMain: {
    '& .content-holder': {
      position: 'relative',
      '& .detail': {
        '& .detail-title': {
          fontSize: '32px',
          color: '#434343',
          fontWeight: 700,
          marginBottom: '20px',
        },
        '& .img-holder': {
          marginBottom: '16px',
          boxShadow: 'rgb(42 67 113 / 15%) 8px 8px 30px 0px',
          borderRadius: '15px',
          overflow: 'hidden',
          '& img': {
            height: 'auto',
            width: '100%',
          },
        },
        '& .description': {
          marginBottom: '40px',
          lineHeight: 1.7,
          fontSize: '18px',
          color: '#73738c',
          '& p': {
            '& strong': {
              color: '#434343',
            },
            '&:not(:last-child)': {
              marginBottom: '15px',
            },
            '& img': {
              width: 'auto',
              objectFit: 'contain',
            },
          },
          '& img': {
            width: '100% !important',
            objectFit: 'contain',
          },
          '& h1, h2, h3, h4, h5, h6': {
            color: theme.palette.primary.main,
          },
          '& table': {
            border: 'none',
            marginTop: '20px',
            '& tbody': {
              '& tr': {
                '& th': {
                  padding: '10px',
                  background: '#009f86',
                  color: '#fff',
                  border: `1px solid red`,
                  '&:not(:last-child)': {
                    borderRight: '1px solid #73738c',
                    borderBottom: 'none',
                  },
                },
                '& td': {
                  padding: '10px',
                  border: '1px solid #73738c',
                },
              },
            },
          },
        },
        '& .page-item': {
          '& .page-link': {
            color: theme.palette.primary.main,
            width: '38px',
            textAlign: 'center',
          },
          '&.active .page-link': {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            color: '#fff',
          },
          '&.disabled .page-link': {
            color: '#6c757d',
            opacity: 0.5,
          },
        },
      },
      '& .notice-list': {
        backgroundColor: '#fff',
        boxShadow: '0 3px 10px #eee',
        padding: '10px 15px',
        borderRadius: '8px',

        '& li a': {
          textDecoration: 'none',
          color: 'currentcolor',
          transition: 'all ease-out 0.7s',
          padding: '5px 0',
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
}));

const PostScroll = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const {posts, isLoading, resourcePath, currentPage, lastPage} = usePosts(
    MODULES.POST,
    searchParams.get('page')
  );
  const {posts: notices} = usePosts(MODULES.NOTICE);

  const paginate = (page) => {
    setSearchParams({page: page});
    window.scrollTo(0, 0, {behaviour: 'smooth'});
  };

  useEffect(() => {
    const hashParts = window.location.href.split('#');
    if (hashParts.length > 1) {
      const blog = document.getElementById(hashParts[1]);
      blog && window.scrollTo(0, blog.getBoundingClientRect().top - 100);
    }
  }, [posts.length]);

  return (
    <>
      <BannerImage title="Our Blogs" />
      <section className={`${classes.detailPageBg}`}>
        <div className={`container-fluid ${classes.detailPageMain}`}>
          <div className="content-holder">
            <div className="row">
              <div className="col-lg detail">
                {posts.map((post, i) => (
                  <div
                    className="mb-5"
                    id={`blog-${post.id}`}
                    key={`activity__${i}`}
                  >
                    <h2 className="detail-title">{post.title}</h2>

                    {post.image ? (
                      <div className="img-holder">
                        <img
                          src={`${resourcePath}/${post.image}`}
                          alt={post.title}
                        />
                      </div>
                    ) : null}

                    <div className="description mb-3">
                      {HTMLReactParser(post.description || '')}
                    </div>
                  </div>
                ))}

                {lastPage > 1 ? (
                  <Pagination className="justify-content-center">
                    <Pagination.Prev
                      className={currentPage === 1 ? 'disabled' : ''}
                      onClick={() => paginate(currentPage - 1)}
                    />
                    {[...Array(lastPage).keys()].map((number) => (
                      <Pagination.Item
                        key={number + 1}
                        active={number + 1 === currentPage}
                        onClick={() => paginate(number + 1)}
                      >
                        {number + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      className={currentPage === lastPage ? 'disabled' : ''}
                      onClick={() => paginate(currentPage + 1)}
                    />
                  </Pagination>
                ) : null}
              </div>
              {notices.length > 0 ? (
                <div className="col-lg-4">
                  <h4>Recent Notices</h4>
                  <ul className="list-unstyled notice-list">
                    {notices.slice(0, 5).map((notice, i) => (
                      <li key={`notice__${i}`}>
                        <Link
                          to={`/notices/${notice.slug}`}
                          className="d-flex align-items-center"
                        >
                          <i className="fa fa-angle-double-right me-2"></i>
                          <span>{notice.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostScroll;
