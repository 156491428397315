import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {Dropdown} from 'react-bootstrap';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import {push as Menu} from 'react-burger-menu';

// style
const styles = (theme) => ({
  mobileMenuBg: {
    background: '#fff',
    padding: '10px 0',
    position: 'fixed',
    zIndex: '9',
    left: 0,
    right: 0,
    top: 0,
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  '@media(max-width: 600px)': {
    mobileMenuBg: {
      display: 'block',
    },
  },
  '@media(min-width: 768px)': {
    mobileMenuBg: {
      display: 'block',
    },
  },
  '@media(min-width: 992px)': {
    mobileMenuBg: {
      display: 'none',
    },
  },
  mobileMenuMain: {
    '& .content-holder': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .logo-divider': {
        display: 'flex',
        alignItems: 'center',
        '& .burger-menu': {
          position: 'relative',
          paddingRight: '40px',
          '& .bm-burger-button': {
            position: 'absolute',
            width: '20px',
            height: '17px',
            left: '10px',
            top: '50%',
            transform: 'translate(0, -50%)',
            outline: 'none',
          },
          '& #react-burger-menu-btn': {
            outline: 'none',
          },
          '& .bm-burger-bars': {
            background: theme.palette.secondary.main,
            height: '12% !important',
          },
          '& .bm-burger-bars-hover': {
            background: theme.palette.secondary.main,
          },
          '& .bm-cross-button': {
            height: '24px',
            width: '24px',
          },
          '& .bm-cross': {
            background: '#fff',
          },
          '& .bm-menu-wrap': {
            position: 'fixed',
            height: '100%',
            left: '0',
            top: '0',
          },
          '& .bm-menu': {
            background: theme.palette.secondary.main,
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em',
          },
          '& .bm-morph-shape': {
            fill: '#373a47',
          },
          '& .bm-item-list': {
            color: '#b8b7ad',
          },
          '& .bm-item': {
            display: 'inline-block',
            margin: '0',
            padding: '0',
            outline: 'none',
            '& .dropdown-submenu-list': {
              listStyle: 'none',
              fontSize: '14px',
              position: 'relative',
              transition: 'all .3s ease-out',
              '&:not(:last-child)': {
                marginBottom: '20px',
              },
              '& .dropdown-submenu-list-link': {
                transition: 'all .3s ease-out',
                color: '#fff',
                fontSize: '16px',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'none',
                  color: '#fff',
                },
              },
              '& .fa': {
                fontSize: '20px',
                color: '#fff',
                marginLeft: '10px',
              },
              '& .submenu-list': {
                margin: '0',
                padding: '0',
                background: '#fff',
                borderRadius: '5px',
                display: 'none',
                transition: 'all .3s ease-out',
                animation: 'opacity 1s',
                '& .submenu-list-item': {
                  listStyle: 'none',
                  borderBottom: `1px solid ${theme.palette.secondary.main}`,
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                  '& a': {
                    color: '#000',
                    display: 'block',
                    padding: '10px 10px',
                    textDecoration: 'none',
                  },
                },
              },
              '&:hover': {
                '& .submenu-list': {
                  display: 'block',
                },
              },
            },
          },
          '& .bm-overlay': {
            background: 'rgba(0, 0, 0, 0.3)',
            top: '0',
            left: '0',
          },
        },
        '& .img-holder': {
          '& img': {
            maxHeight: '60px',
          },
        },
      },
      '& .apply-buttons': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .fa': {
          marginRight: '5px',
        },
        '& .apply-button-2': {
          '& .btn-outline-primary': {
            padding: '5px',
            fontSize: '12px',
            borderRadius: '5px',
            color: '#fff',
            background: '#009f86',
            border: 'none',
            boxShadow: 'inset 0 0 0 0 #0073b1',
            transition: 'all .3s ease-out',
            transform: 'scale(1)',
            animation: 'pulse 2s infinite',
            '&:hover': {
              background: '#069032',
              border: '1px solid #069032',
            },
          },
        },
      },
      '& .login-buttons': {
        '& .top-header-link': {
          color: '#434343',
          background: 'transparent',
          border: 'none',
          outline: 'none',
          fontSize: '14px',
          position: 'relative',
          transition: 'all .3s ease-out',
          '&:hover': {
            color: theme.palette.secondary.main,
            background: 'transparent !important',
            border: 'none',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '18px',
          },
          '& span': {
            marginLeft: '5px',
          },
        },
        '& .dropdown-item': {
          fontSize: '13px',
          padding: '5px 10px 0px 10px',
          transition: 'all .3s ease-out',
          borderBottom: '1px solid #ececec',
          '&:last-child': {
            borderBottom: 'none',
          },
          '&:hover': {
            paddingLeft: '15px',
            color: '#fff',
            background: theme.palette.secondary.main,
            borderColor: 'none',
          },
        },
        '& .dropdown-menu': {
          minWidth: '170px',
          padding: 0,
        },
        '& .dropdown-menu.show': {
          marginTop: '10px !important',
          border: 'none !important',
        },
        '& .btn-primary:focus': {
          boxShadow: 'none !important',
        },
        '& .btn-primary': {
          backgroundColor: 'transparent',
          border: 'none',
          color: theme.palette.secondary.main,
        },
        '& .btn-primary:hover, .btn-primary:focus, .btn-primary:active': {
          background: 'transparent !important',
        },
      },
    },
  },
  '@keyframes pulse5': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: `0 0 0 0 #fff`,
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: `0 0 0 10px rgba(255, 255, 255, 0)`,
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: `0 0 0 0 rgba(255, 255, 255, 0)`,
    },
  },
  '@keyframes opacity': {
    '0%': {
      transform: 'translateY(20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
});

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({menuOpen: state.isOpen});
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({menuOpen: false});
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState((state) => ({menuOpen: !state.menuOpen}));
  }

  render() {
    const {profile, menu, classes} = this.props;

    return (
      <section className={`${classes.mobileMenuBg}`}>
        <div className={`container-fluid ${classes.mobileMenuMain}`}>
          <div className="content-holder">
            <div className="logo-divider">
              <div className="burger-menu">
                <Menu
                  disableAutoFocus
                  isOpen={this.state.menuOpen}
                  onStateChange={(state) => this.handleStateChange(state)}
                >
                  <ul className="dropdown-submenu">
                    {menu.map((item, i) => {
                      if (item.subMenu && item.subMenu.length) {
                        return (
                          <li key={i + '___'} className="dropdown-submenu-list">
                            <span className="dropdown-submenu-list-link d-flex align-items-center">
                              {item.name}
                              {item.subMenu ? (
                                <i className="fa fa-angle-down" />
                              ) : null}
                            </span>
                            <ul className="submenu-list">
                              {item.subMenu.map((submenuItem, i) => {
                                return item.subMenu ? (
                                  <li
                                    key={submenuItem.name + i}
                                    className="submenu-list-item"
                                    onClick={() => this.closeMenu()}
                                  >
                                    <Link to={submenuItem.slug}>
                                      {submenuItem.name}
                                    </Link>
                                  </li>
                                ) : null;
                              })}
                            </ul>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            key={item.name + i}
                            className="dropdown-submenu-list"
                            onClick={() => this.closeMenu()}
                          >
                            <Link
                              to={item.slug}
                              className="dropdown-submenu-list-link"
                            >
                              {item.name}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </Menu>
              </div>
              <div className="img-holder">
                <Link to="/">
                  <img
                    src={`${profile.logo_path}/${profile.logo}`}
                    alt={`${profile.title} Logo`}
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            <div className="buttons d-flex align-items-center">
              <div className="apply-buttons">
                {/* <div className='apply-button-2'>
                                    <a
                                        href='#'
                                        target='_blank'
                                        className='btn btn-outline-primary'
                                        rel='noreferrer'
                                    >
                                        <i className='fa fa-pencil' />
                                        Apply Online
                                    </a>
                                </div> */}
              </div>
              <div className="login-buttons">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-autoclose-true">
                    <LockOpenRoundedIcon />
                    PORTAL
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="https://edigitalnepal.com/sms/login?role=admin"
                      target="_blank"
                      className="top-header-link"
                    >
                      Admin Login
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="https://edigitalnepal.com/sms/login?role=teacher"
                      target="_blank"
                      className="top-header-link"
                    >
                      Teacher Login
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="https://edigitalnepal.com/sms/login?role=student"
                      target="_blank"
                      className="top-header-link"
                    >
                      Student / Parent Login
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withStyles(styles, {withTheme: true})(MobileMenu);
