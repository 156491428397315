import React from 'react';
import {makeStyles} from '@mui/styles';
import Image from './../../../assets/images/shreekul-intro1.jpeg';
import Image2 from './../../../assets/images/shreekul-intro2.jpg';
import VideoBlock from '../../video-block/1';
import Button from '@mui/material/Button';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Fade from 'react-reveal';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  introductionBg: {
    position: 'relative',
    overflow: 'hidden',
  },
  '@media(max-width: 600px)': {
    introductionBg: {
      padding: '50px 0',
    },
  },
  '@media(min-width: 768px)': {
    introductionBg: {
      padding: '50px 0',
    },
  },
  '@media(min-width: 992px)': {
    introductionBg: {
      padding: '100px 0 190px 0',
    },
  },
  introductionMain: {
    '& .content-holder': {
      '& .marginBottom': {
        '& .img-holder': {
          width: '100%',
          position: 'relative',
          '& .bg-stretch': {
            height: '100%',
            width: '100%',
            borderRadius: '15px',
          },
          '& .first-image': {
            position: 'absolute',
            bottom: '-100px',
            right: '-90px',
            height: '200px',
            width: '180px',
            border: '4px solid #fff',
            borderRadius: '15px',
          },
        },
        '& .content': {
          '& .sub-title': {
            fontSize: '18px',
            color: theme.palette.secondary.main,
            marginBottom: 0,
          },
          '& .title': {
            fontSize: '32px',
            color: '#434343',
            fontWeight: 700,
          },
          '& .description': {
            fontSize: '16px',
            color: '#73738c',
            lineHeight: 2,
            '& li': {
              marginBottom: '15px',
            },
          },
          '& .MuiButton-root': {
            background: theme.palette.secondary.main,
            borderRadius: '8px',
            boxShadow: `0px 10px 50px ${theme.palette.secondary.main}4f`,
            textTransform: 'capitalize',
            transition: 'all 0.3s ease-out',
            '& .MuiButton-endIcon': {
              marginLeft: '2px',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '16px',
              marginLeft: '0',
            },
            '&:hover': {
              background: theme.palette.primary.main,
              boxShadow: `0px 10px 50px ${theme.palette.primary.main}4f`,
              color: '#fff',
            },
          },
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '&:not(:last-child)': {
            marginBottom: '30px',
          },
          '& .img-holder': {
            height: '250px',
            '& .first-image': {
              display: 'none',
            },
          },
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '&:not(:last-child)': {
            marginBottom: '30px',
          },
          '& .img-holder': {
            height: '250px',
            '& .first-image': {
              display: 'none',
            },
          },
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .marginBottom': {
          '&:not(:last-child)': {
            marginBottom: '0',
          },
          '& .img-holder': {
            height: '450px',
            '& .first-image': {
              display: 'block',
            },
          },
        },
      },
    },
  },
}));

const Introduction = () => {
  const classes = useStyles();
  return (
    <section className={`${classes.introductionBg}`}>
      <div className={`container-fluid ${classes.introductionMain}`}>
        <div className="content-holder">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5 col-md-12 col-sm-12 marginBottom">
              <Fade left duration={2000}>
                <div className="img-holder">
                  <div
                    className="bg-stretch background-image"
                    style={{backgroundImage: `url('${Image}')`}}
                  />
                  <div
                    className="background-image first-image"
                    style={{backgroundImage: `url('${Image2}')`}}
                  />
                  <VideoBlock />
                </div>
              </Fade>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 marginBottom">
              <Fade right duration={2000}>
                <div className="content">
                  <h6 className="sub-title">Introduction</h6>
                  <h2 className="title">About Us</h2>
                  <div className="description">
                    <p>
                      With the aid of smart classes, Shreekul has been providing
                      wider horizons of learning to the students. we work
                      collaboratively with parents and other stakeholders to
                      transform students into global citizens equipped with the
                      21st century skills.
                    </p>
                    <ul>
                      <li>
                        To create a conducive atmosphere of learning that
                        promotes innovation individuation and initiation in
                        children.
                      </li>
                      <li>
                        To be a leading organization in transforming children
                        into insightful leaders.
                      </li>
                    </ul>
                  </div>
                  <Button
                    component={Link}
                    to="/about/introduction"
                    variant="contained"
                    endIcon={<ArrowForwardRoundedIcon />}
                  >
                    Read More
                  </Button>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
