import axios from 'axios';
import {useSWRConfig} from 'swr';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url) => axios.get(url);

export default function usePost(module, slug) {
  const url = `${useSWRConfig().apiUrl}/${module}/${slug}`;
  const {data, error} = useSWRImmutable(url, fetcher);

  let post = data?.data?.data[module.slice(0, -1)];

  return {
    post: post,
    resourcePath: post?.image_path,
    isLoading: !error && !data,
    isError: error,
  };
}
