import {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {makeStyles} from '@mui/styles';
import WebsiteContext from '../../../contexts/website-context';
import {useSWRConfig} from 'swr';

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .modal-content': {
      borderRadius: '15px',
      border: 'none',
      boxShadow: '0 10px 30px #00000024',
    },
    '& .modal-header': {
      borderBottom: 'none',
      padding: '16px 24px',
    },
    '& .modal-body': {
      padding: '16px 24px',
    },
    '& .btn': {
      background: theme.palette.secondary.main,
      borderRadius: '8px',
      boxShadow: `0px 10px 50px ${theme.palette.secondary.main}4f`,
      color: '#fff',
      transition: 'all 0.3s ease-out',
      '&:hover': {
        background: theme.palette.primary.main,
        boxShadow: `0px 10px 50px ${theme.palette.primary.main}4f`,
        color: '#fff',
      },
    },
  },
}));

export default function Popup1({popup, resourcePath}) {
  const classes = useStyles();
  const {apiUrl} = useSWRConfig();
  const {profile} = useContext(WebsiteContext);
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal
        key={`popup__${popup.id}`}
        className={classes.modal}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{popup.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {popup.image ? (
            <p>
              <Link to={`/notices/${popup.slug}`}>
                <img
                  src={`${
                    resourcePath ?? apiUrl.replace('/api', '/uploads/notices')
                  }/${popup.image}`}
                  className="img-fluid"
                  alt={popup.title}
                />
              </Link>
            </p>
          ) : null}
          <p>{popup.summary}</p>
          {profile.applyLink ? (
            <a
              className="btn"
              href={profile.applyLink}
              target="_blank"
              variant="outlined"
              rel="noreferrer"
            >
              Apply Now
            </a>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
}
