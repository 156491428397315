import React from 'react';
import {makeStyles} from '@mui/styles';
import PulseLoader from 'react-spinners/PulseLoader';

const useStyles = makeStyles((theme) => ({
  loaderBg: {
    padding: '50px 0',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const Loader = () => {
  const classes = useStyles();
  return (
    <div className={`${classes.loaderBg}`}>
      <PulseLoader color={window.primary || '#000'} size={15} />
    </div>
  );
};

export default Loader;
