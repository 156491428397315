import React from 'react';
import {makeStyles} from '@mui/styles';
import usePosts from '../hooks/use-posts';
import {AVS, MODULES} from '../utils/constants';
import Fade from 'react-reveal';
import BannerImage from '../components/banner-image/1';
import {useSWRConfig} from 'swr';
import {useSearchParams} from 'react-router-dom';
import Pagination1 from '../components/pagination/1';

const useStyles = makeStyles((theme) => ({
  teamComponentBg: {
    overflow: 'hidden',
    padding: '90px 0 50px',
  },
  teamComponentMain: {
    '& .content-holder': {
      '& .text-holder': {
        textAlign: 'center',
        marginBottom: '50px',
        '& .title': {
          fontSize: '32px',
          color: '#434343',
          fontWeight: '700',
        },
      },
      '& .marginBottom': {
        marginBottom: '30px',
        '& .team-list': {
          background: '#fff',
          transition: 'all .3s ease-out',
          boxShadow: '0 10px 50px rgb(166 209 237 / 20%)',
          borderRadius: '15px',
          height: '100%',
          '& .img-holder': {
            height: '300px',
            width: '100%',
            position: 'relative',
            transition: 'all .3s ease-out',
            '& .bg-stretch': {
              height: '100%',
              width: '100%',
              borderRadius: '10px',
              backgroundPosition: 'top center',
            },
          },
          '& .post': {
            padding: '0 10px',
            zIndex: '1',
            position: 'relative',
            transition: 'all .3s ease-out',
            '& .post-inner': {
              background: theme.palette.secondary.main,
              textAlign: 'center',
              padding: '10px',
              borderRadius: '10px',
              marginTop: '-37px',
              '& .name': {
                color: '#fff',
                fontSize: '18px',
                fontWeight: 600,
                marginBottom: '5px',
              },
              '& .designation': {
                color: '#fff',
                fontSize: '14px',
                margin: 0,
              },
            },
          },
          '&:hover': {
            '& .img-holder': {
              transform: 'translateY(-10px)',
            },
            '& .post': {
              transform: 'translateY(-20px)',
            },
          },
        },
      },
    },
  },
}));

const TeamList = () => {
  const [searchParams] = useSearchParams();
  const {
    posts: teams,
    resourcePath,
    currentPage,
    lastPage,
  } = usePosts(MODULES.TEAM, searchParams.get('page'));
  const {siteName} = useSWRConfig();
  const classes = useStyles();

  return (
    <>
      <BannerImage
        baseMenu={{title: 'People'}}
        title={siteName === AVS ? 'Board of Directors' : 'Our Team'}
      />
      <section className={`${classes.teamComponentBg}`}>
        <div className={`container-fluid ${classes.teamComponentMain}`}>
          <div className="content-holder">
            <div className="row justify-content-center">
              {teams.map((item, i) => {
                return (
                  <Fade bottom duration={2000} key={i + '__'}>
                    <div className="col-lg-3 col-md-6 col-sm-12 marginBottom">
                      <div className="team-list">
                        <div className="img-holder">
                          <div
                            className="bg-stretch background-image cover"
                            style={{
                              backgroundImage: `url('${resourcePath}/${item.image}')`,
                            }}
                          />
                        </div>
                        <div className="post">
                          <div className="post-inner">
                            <h4 className="name">{item.name}</h4>
                            <h4 className="designation">{item.position}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                );
              })}
            </div>

            <Pagination1 currentPage={currentPage} lastPage={lastPage} />
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamList;
