import React from 'react';
import {styled} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Button} from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    borderTop: 'none',
    borderBottom: 'none',
    border: 'none',
  },
  '& .MuiPaper-root': {
    margin: '50px 0',
    boxShadow: 'none',
    borderRadius: '15px',
    background: 'transparent',
    overflowY: 'unset',
  },
  '& .MuiBackdrop-root': {
    background:
      'linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgb(22, 28, 36) 100%)',
  },
  '& .MuiTypography-root': {
    padding: 0,
  },
  '& .MuiButtonBase-root': {
    border: 'none',
    color: '#fff',
    background: 'transparent',
    boxShadow: 'none',
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    minWidth: 'unset',
    position: 'absolute',
    right: -25,
    top: -15,
    opacity: 1,
    '& .MuiSvgIcon-root': {
      fontSize: '32px',
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      background: 'transparent',
      color: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
  '@media(max-width: 600px)': {
    '& .MuiPaper-root': {
      maxWidth: '350px',
    },
    '& iframe': {
      width: '350px',
      height: '350px',
    },
  },
  '@media(min-width: 768px)': {
    '& .MuiPaper-root': {
      maxWidth: '700px',
    },
    '& iframe': {
      width: '700px',
      height: '500px',
    },
  },
  '@media(min-width: 992px)': {
    '& .MuiPaper-root': {
      maxWidth: '900px',
    },
    '& iframe': {
      width: '900px',
      height: '500px',
      borderRadius: '15px',
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  videoBlockBg: {
    position: 'absolute',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    animation: `$ripple2 3s linear infinite`,
    background: '#fff',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '50%',
      animation: 'inherit',
      animationDelay: '1s',
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: '50%',
      animation: 'inherit',
      animationDelay: '2s',
    },
  },
  '@media(max-width: 600px)': {
    videoBlockBg: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  '@media(min-width: 768px)': {
    videoBlockBg: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  '@media(min-width: 992px)': {
    videoBlockBg: {
      top: '100%',
      left: '100%',
      transform: 'unset',
    },
  },
  videoBlockMain: {
    position: 'relative',
    zIndex: 2,
    '& .video-content-holder': {
      '& .MuiButton-outlinedPrimary': {
        border: 'none',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        minWidth: 'unset',
        padding: '0',
        borderRadius: '50%',
        height: '60px',
        width: '60px',
        '& span': {
          fontWeight: 700,
          color: '#434343',
          fontSize: '22px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '60px',
          color: theme.palette.secondary.main,
        },
        '&:hover': {
          background: 'transparent',
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .video-content-holder': {
        '& .MuiButton-outlinedPrimary': {
          margin: '0 auto',
        },
      },
    },
  },
  '@keyframes ripple2': {
    '0%': {
      boxShadow: '0 0 0 20px rgba(255,255,255, 0.5)',
    },
    '100%': {
      boxShadow: '0 0 0 100px rgba(255,255,255, 0)',
    },
  },
}));
const VideoBlock = () => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <section className={`${classes.videoBlockBg}`}>
      <div className={`${classes.videoBlockMain}`}>
        <div className="video-content-holder">
          <div className="popup-bg">
            <Button variant="outlined" onClick={handleClickOpen}>
              <PlayCircleOutlineRoundedIcon />
            </Button>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogContent dividers>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/X981llE6eMM"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </DialogContent>
              <Button variant="contained" color="primary" onClick={handleClose}>
                <CloseRounded />
              </Button>
            </BootstrapDialog>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoBlock;
