import {makeStyles} from '@mui/styles';
import {Pagination} from 'react-bootstrap';
import {useSearchParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .page-item.active .page-link': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const Pagination1 = ({currentPage, lastPage}) => {
  const [, setSearchParams] = useSearchParams();
  const classes = useStyles();

  const paginate = (page) => {
    setSearchParams({page: page});
    window.scrollTo(0, 0, {behaviour: 'smooth'});
  };

  return lastPage > 1 ? (
    <Pagination className={`${classes.pagination} justify-content-center`}>
      <Pagination.Prev
        className={currentPage === 1 ? 'disabled' : ''}
        onClick={() => paginate(currentPage - 1)}
      />
      {[...Array(lastPage).keys()].map((number) => (
        <Pagination.Item
          key={number + 1}
          active={number + 1 === currentPage}
          onClick={() => paginate(number + 1)}
        >
          {number + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        className={currentPage === lastPage ? 'disabled' : ''}
        onClick={() => paginate(currentPage + 1)}
      />
    </Pagination>
  ) : null;
};

export default Pagination1;
