import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  breadCrumbBg: {
    textAlign: 'center',
    margin: '0 auto',
  },
  breadCrumbMain: {
    '& .content-holder': {
      '& .MuiBreadcrumbs-ol': {
        justifyContent: 'center',
        '& .MuiBreadcrumbs-separator': {
          '& .MuiSvgIcon-root': {
            color: '#fff',
          },
        },
        '& .MuiBreadcrumbs-li': {
          '& .MuiTypography-root': {
            color: '#fff',
          },
          '& a': {
            color: '#f1f1f1',
            textDecoration: 'none',
            transition: 'all .3s ease-out',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  },
}));

const BreadCrumb = ({title, baseMenu}) => {
  const classes = useStyles();
  let baseTitle = 'Home';
  let baseLink = '/';

  if (baseMenu?.title) {
    baseTitle = baseMenu.title;
    baseLink = baseMenu?.link;
  }

  return (
    <section className={`${classes.breadCrumbBg}`}>
      <div className={`${classes.breadCrumbMain}`}>
        <div className="content-holder">
          <div className={classes.root}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {baseLink ? (
                <Link to={baseLink}>{baseTitle}</Link>
              ) : (
                <Typography color="textPrimary">{baseTitle}</Typography>
              )}
              <Typography color="textPrimary">{title}</Typography>
            </Breadcrumbs>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BreadCrumb;
