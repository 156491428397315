import React from 'react';
import {makeStyles} from '@mui/styles';
import BannerImage from '../components/banner-image/1';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import usePosts from '../hooks/use-posts';
import {MODULES} from '../utils/constants';
import {useSearchParams} from 'react-router-dom';
import Pagination1 from '../components/pagination/1';

const useStyles = makeStyles((theme) => ({
  detailPageBg: {
    padding: '90px 0 50px',
  },
  detailPageMain: {
    '& .download-title': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& .col-sn': {
      maxWidth: '70px',
      textAlign: 'center',
    },
    '& .col-file': {
      maxWidth: 'max-content',
    },
    '& .download-item': {
      backgroundColor: '#fff',
      boxShadow: '0 3px 10px #eee',
      padding: '10px',
      borderRadius: '8px',
    },
    '& .btn': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
}));

const Downloads = () => {
  const [searchParams] = useSearchParams();
  const {
    isLoading,
    posts: downloads,
    resourcePath,
    currentPage,
    lastPage,
  } = usePosts(MODULES.DOWNLOAD, searchParams.get('page'));
  const classes = useStyles();

  return (
    <>
      <BannerImage title="Downloads" />
      <section className={`${classes.detailPageBg}`}>
        <div className={`container-fluid ${classes.detailPageMain}`}>
          <div className="content-holder">
            {isLoading ? (
              <div className="text-center">Loading...</div>
            ) : (
              <div className="download-title mb-2 d-none d-md-block">
                <div className="row">
                  <div className="col col-sn">S.N.</div>
                  <div className="col">Title</div>
                </div>
              </div>
            )}

            {downloads.map((download, i) => (
              <div key={`download_${i}`} className="download-item mb-2">
                <div className="row align-items-center">
                  <div className="col d-none d-md-block col-sn">{i + 1}</div>
                  <div className="col">
                    <div className="row">
                      <div className="col-lg">
                        <div className="fw-bold">{download.title}</div>
                        <div className="small text-muted">{download.title}</div>
                      </div>
                      <div className="col-lg col-file">
                        {download.download_files.length > 0 ? (
                          <div className="download-files mt-2">
                            {download.download_files.map((file, i) => (
                              <a
                                href={`${resourcePath}/${file.name}`}
                                className="btn me-2 mt-1"
                                download
                                key={`download__file_${i}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <ArrowDownward /> Download File{' '}
                                {download.download_files.length > 1
                                  ? `(${i + 1})`
                                  : ''}
                              </a>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <Pagination1 currentPage={currentPage} lastPage={lastPage} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Downloads;
