import React from 'react';
import Slider from 'react-slick';
import Fade from 'react-reveal';
import {makeStyles} from '@mui/styles';

// style
const useStyles = makeStyles((theme) => ({
  testimonialSliderBg: {
    padding: '100px 0 100px 0',
  },
  '@media(max-width: 600px)': {
    testimonialSliderBg: {
      padding: '50px  0',
    },
  },
  '@media(min-width: 768px)': {
    testimonialSliderBg: {
      padding: '50px  0',
    },
  },
  '@media(min-width: 992px)': {
    testimonialSliderBg: {
      padding: '50px 0 100px 0',
    },
  },
  testimonialSliderMain: {
    '& .MuiButton-outlinedPrimary': {
      marginBottom: '20px',
      color: theme.palette.primary.main,
      border: '2px solid #009f86',
      boxShadow: 'inset 0 0 0 0 #fff',
      transition: 'all .3s ease-out',
      borderRadius: '30px',
      fontWeight: '500',
      textTransform: 'capitalize',
      '&:hover': {
        background: `#fff`,
        color: '#fff',
        border: '2px solid blue',
        boxShadow: 'inset 120px 0 0 0 blue',
      },
    },
    '& .content-holder': {
      '& .testimonial-text-holder': {
        marginBottom: '30px',
        '& .sub-title': {
          '& h4': {
            color: theme.palette.secondary.main,
            fontSize: '18px',
            marginBottom: 0,
          },
        },
        '& .title': {
          fontSize: '32px',
          fontWeight: '700',
          color: '#434343',
        },
      },
      '& .slick-prev': {
        display: 'none !important',
      },
      '& .slick-next': {
        display: 'none !important',
      },
      '& .slick-dots': {
        bottom: '-50px',
        '& li': {
          '& button': {
            '&::before': {
              opacity: '1',
              fontSize: '7px',
              color: theme.palette.primary.main,
              width: '35px',
            },
          },
        },
        '& .slick-active': {
          '& button': {
            '&::before': {
              color: theme.palette.secondary.main,
              fontSize: '12px',
            },
          },
        },
      },
      '& .slider-list': {
        position: 'relative',
        display: 'block',
        zIndex: '1',
        '&::before': {
          content: "''",
          position: 'absolute',
          border: '1px solid #cccccc73',
          right: '20px',
          bottom: '-20px',
          top: '20px',
          left: '0',
          zIndex: '-1',
          borderRadius: '15px',
        },
        '& .content': {
          position: 'relative',
          padding: '50px 50px 20px 50px',
          background: '#fff',
          boxShadow: '8px 8px 30px 0px rgb(42 67 113 / 15%)',
          zIndex: '1',
          borderRadius: '15px',
          '&::before': {
            content: "''",
            position: 'absolute',
            borderTop: `95px solid ${theme.palette.secondary.main}`,
            borderRight: '95px solid transparent',
            top: '0',
            left: '0',
            zIndex: '-1',
          },
          '&::after': {
            content: "'\\f10e'",
            position: 'absolute',
            font: 'normal normal normal 14px/1 FontAwesome',
            color: '#fff',
            top: '10px',
            left: '10px',
            zIndex: '1',
            fontSize: '35px',
            fontWeight: '900',
            lineHeight: '40px',
          },
          '& .text-holder': {
            '& p': {
              lineHeight: '2',
              color: '#73738c',
              marginBottom: '10px',
              fontSize: '16px',
            },
          },
          '& .profile-holder': {
            display: 'flex',
            alignItems: 'center',
            '& .img-holder': {
              height: '80px',
              width: '80px',
              marginRight: '20px',
              '& .bg-stretch': {
                boxShadow: '0 20px 40px rgba(232,231,231,1)',
                borderRadius: '50%',
                height: '80px',
                width: '80px',
              },
            },
            '& .designation': {
              '& .name': {
                fontWeight: '700',
                fontSize: '18px',
                color: '#434343',
              },
              '& .position': {
                fontWeight: '500',
                fontSize: '14px',
                color: '#73738c',
              },
            },
          },
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .slick-slide': {
          // paddingRight: '69px',
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .slick-slide': {
          padding: '20px 0',
        },
        '& .center': {
          padding: '0 10px',
          marginRight: '10px',
        },
      },
    },
  },
}));

const TestimonialSlider = ({data: testimonials, resourcePath}) => {
  const classes = useStyles();
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return testimonials.length > 0 ? (
    <section className={`${classes.testimonialSliderBg}`}>
      <div className={`container-fluid ${classes.testimonialSliderMain}`}>
        <div className="content-holder">
          <Fade bottom duration={2000}>
            <div className="testimonial-text-holder text-center">
              <div className="sub-title">
                <h4>Testimonial</h4>
              </div>
              <h2 className="title">What People Says</h2>
            </div>
          </Fade>
          <Slider {...settings}>
            {testimonials.map((item, i) => {
              return (
                <Fade bottom duration={2000} key={i}>
                  <div className="slider-list center">
                    <div className="content ">
                      <div className="text-holder">
                        <p>
                          {item.message.length > 200
                            ? item.message.substring(0, 200) + '...'
                            : item.message}
                        </p>
                      </div>
                      <div className="profile-holder">
                        <div className="img-holder">
                          <div
                            className="bg-stretch background-image cover"
                            style={{
                              backgroundImage: `url('${resourcePath.replace(
                                'testimo$testimonials',
                                'testimonials'
                              )}/${item.image}')`,
                            }}
                          />
                        </div>
                        <div className="designation">
                          <div className="name">
                            <span>{item.name}</span>
                          </div>
                          <div className="position">
                            <span>{item.job_title}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  ) : null;
};

export default TestimonialSlider;
