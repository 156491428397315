import React from 'react';
import {Link} from 'react-router-dom';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import {makeStyles} from '@mui/styles';
import Fade from 'react-reveal';
import dayjs from 'dayjs';
import Image2 from '../../../assets/images/events-shape.png';

// style
const useStyles = makeStyles((theme) => ({
  eventSectionBg: {
    position: 'relative',
  },
  '@media(max-width: 600px)': {
    eventSectionBg: {
      padding: '50px 0',
    },
  },
  '@media(min-width: 768px)': {
    eventSectionBg: {
      padding: '50px 0',
    },
  },
  '@media(min-width: 992px)': {
    eventSectionBg: {
      padding: '100px 0',
    },
  },
  eventSectionMain: {
    '& .content-holder': {
      position: 'relative',
      '&::before': {
        content: "''",
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%',
        background: `url('${Image2}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        zIndex: -1,
      },
      '& .text-holder': {
        textAlign: 'center',
        marginBottom: '30px',
        '& .sub-title': {
          fontSize: '18px',
          color: theme.palette.secondary.main,
          marginBottom: '0',
        },
        '& .title': {
          fontSize: '32px',
          fontWeight: 700,
          color: '#434343',
        },
      },
      '& .event-list': {
        boxShadow: '0 5px 50px rgb(156 185 226 / 20%)',
        background: '#fff',
        padding: '40px',
        borderRadius: '15px',
        maxWidth: '800px',
        margin: '0 auto',
        position: 'relative',
        marginBottom: '30px',
        transition: 'all .3s ease-out',
        '&::before': {
          content: "''",
          position: 'absolute',
          left: '-5px',
          top: 0,
          background: theme.palette.secondary.main,
          height: '100%',
          width: '10%',
          borderRadius: '15px 0 0 15px',
          zIndex: -1,
        },
        '&::after': {
          content: "''",
          position: 'absolute',
          left: '0',
          top: 0,
          background: '#fff',
          height: '100%',
          width: '10%',
          borderRadius: '15px 0 0 15px',
          zIndex: -1,
        },
        '& .content': {
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
          '& .date-holder': {
            '& span': {
              color: '#434343',
              fontSize: '16px',
              fontWeight: 500,
            },
          },
          '& .read-more': {
            '& a': {
              textDecoration: 'none',
              color: '#434343',
              fontSize: '16px',
              fontWeight: 500,
              transition: 'all .3s ease-out',
              '& .MuiSvgIcon-root': {
                fontSize: '18px',
              },
              '&:hover': {
                color: theme.palette.primary.main,
              },
            },
          },
        },
        '& .event-title': {
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          '& a': {
            fontSize: '22px',
            fontWeight: 700,
            textDecoration: 'none',
            color: '#434343',
            transition: 'all .3s ease-out',
            '&:hover': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
      '& .all-event': {
        '& a': {
          margin: '0 auto',
          height: '30px',
          width: '30px',
          background: theme.palette.secondary.main,
          borderRadius: '50%',
          display: 'grid',
          placeItems: 'center',
          boxShadow: '8px 8px 30px 0px rgb(220 21 42 / 15%)',
          transition: 'all .3s ease-out',
          '& .MuiSvgIcon-root': {
            fontSize: '18px',
            color: '#fff',
          },
          '&:hover': {
            background: theme.palette.primary.main,
            transform: 'scale(1.1)',
          },
        },
      },
      '& .all-event2': {
        '& a': {
          margin: '0 auto',
          height: '30px',
          width: '30px',
          background: 'linear-gradient(to left, #00c6fb 0%, #005bea 100%)',
          borderRadius: '50%',
          display: 'grid',
          placeItems: 'center',
          boxShadow: '8px 8px 30px 0px rgb(220 21 42 / 15%)',
          transition: 'all .3s ease-out',
          '& .MuiSvgIcon-root': {
            fontSize: '18px',
            color: '#fff',
          },
          '&:hover': {
            background: 'linear-gradient(to right, #00c6fb 0%, #005bea 100%)',
            transform: 'scale(1.1)',
          },
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .event-list': {
          '& .content': {
            flexWrap: 'wrap',
            justifyContent: 'center',
          },
          '& .event-title': {
            textAlign: 'center',
          },
        },
        '& .event-list2': {
          '& .content': {
            flexWrap: 'wrap',
            justifyContent: 'center',
          },
          '& .event-title': {
            textAlign: 'center',
          },
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        '& .event-list': {
          '& .content': {
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
          },
          '& .event-title': {
            textAlign: 'left',
          },
        },
        '& .event-list2': {
          '& .content': {
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
          },
          '& .event-title': {
            textAlign: 'left',
          },
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .event-list': {
          '& .content': {
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
          },
          '& .event-title': {
            textAlign: 'left',
          },
        },
        '& .event-list2': {
          '& .content': {
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
          },
          '& .event-title': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  '@keyframes section-animation-2': {
    '0%': {
      width: '0',
    },
    '15%': {
      width: '125%',
    },
    '85%': {
      opacity: 1,
    },
    '90%': {
      width: '125%',
      opacity: 0,
    },
    to: {
      width: 0,
      opacity: 0,
    },
  },
}));

const NoticeSection = ({data: notices}) => {
  const classes = useStyles();
  return notices.length ? (
    <section className={`${classes.eventSectionBg}`}>
      <div className={`container-fluid ${classes.eventSectionMain}`}>
        <div className="content-holder">
          <Fade bottom duration={2000}>
            <div className="text-holder">
              <h6 className="sub-title">Notice</h6>
              <h2 className="title">Recent Notices</h2>
            </div>
          </Fade>
          {notices.map((item, i) => {
            // eslint-disable-next-line array-callback-return
            return (
              <Fade bottom duration={2000} key={i++}>
                <div className="event-list">
                  <div className="content">
                    <div className="date-holder">
                      <span>{dayjs().format('MMMM MM, YYYY')}</span>
                    </div>
                    <div className="read-more">
                      <Link to={`/notices/${item.slug}`}>
                        Read More
                        <ArrowForwardRoundedIcon />
                      </Link>
                    </div>
                  </div>
                  <div className="event-title">
                    <Link to={`/notices/${item.slug}`}>{item.title}</Link>
                  </div>
                </div>
              </Fade>
            );
          })}
          <Fade bottom duration={2000}>
            <div className="all-event">
              <Link to="/notices">
                <ArrowForwardRoundedIcon />
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  ) : null;
};

export default NoticeSection;
