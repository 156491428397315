import axios from 'axios';
import {useSWRConfig} from 'swr';
import useSWRImmutable from 'swr/immutable';

const fetcher = (url) => axios.get(url);

export default function useGallery(module, id = null) {
  const url = `${useSWRConfig().apiUrl}/gallery/${module}`;
  const {data, error} = useSWRImmutable(url, fetcher);

  let posts = data?.data?.data[`${module}s`] || [];

  if (id) {
    posts = posts.find((post) => post.id == id);
  }

  return {
    posts: posts,
    isLoading: !error && !data,
    isError: error,
  };
}
