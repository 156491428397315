import React from 'react';
import {makeStyles} from '@mui/styles';
import Slider from 'react-slick';
import {Button} from 'react-bootstrap';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  homeSliderBg: {
    position: 'relative',
  },
  '@media(max-width: 600px)': {
    homeSliderBg: {
      marginTop: '80px',
    },
  },
  '@media(min-width: 768px)': {
    homeSliderBg: {
      marginTop: '80px',
    },
  },
  '@media(min-width: 992px)': {
    homeSliderBg: {
      marginTop: '0',
    },
  },
  homeSliderMain: {
    '& .content-holder': {
      '& .slick-arrow': {
        zIndex: 1,
        color: '#fff',
        width: '40px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none!important',
        '&::before': {
          content: "''",
        },
      },
      '& .slick-prev': {
        left: '7px',
        [theme.breakpoints.up('md')]: {
          left: '30px',
        },
      },
      '& .slick-next': {
        right: '0',
        [theme.breakpoints.up('md')]: {
          right: '20px',
        },
      },
      '& .home-slider-item': {
        '& .img-holder': {
          width: '100%',
          '& .bg-stretch': {
            height: '350px',
            width: '100%',
            display: 'flex',
            alignItems: 'end',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
              height: '600px',
            },
            [theme.breakpoints.up('lg')]: {
              height: '100vh',
            },
            '&::before': {
              content: '""',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              position: 'absolute',
              background: 'rgb(0 0 0 / 30%)',
            },
            '& .content': {
              position: 'relative',
              paddingTop: '10px',
              paddingBottom: '10px',
              [theme.breakpoints.up('md')]: {
                paddingTop: '40px',
                paddingBottom: '40px',
              },
              zIndex: 1,
              '& .title': {
                color: '#fff',
                fontSize: '20px',
                fontWeight: '700',
                display: 'inline-block',
                background: theme.palette.primary.main,
                padding: '7px 15px',
                marginBottom: 0,
                [theme.breakpoints.up('md')]: {
                  fontSize: '32px',
                },
              },
              '& .description': {
                color: '#fff',
                display: 'inline-block',
                fontSize: '16px',
                marginBottom: 0,
                padding: '7px 15px',
                background: theme.palette.secondary.main,
                minWidth: '150px',
                [theme.breakpoints.up('md')]: {
                  fontSize: '18px',
                  minWidth: '280px',
                },
              },
            },
          },
        },
      },
    },
  },
}));

const Arrow = (props) => {
  const {className, onClick} = props;
  return (
    <Button className={className} onClick={onClick}>
      {props.type === 'left' ? <ArrowBackIos /> : <ArrowForwardIos />}
    </Button>
  );
};

const HomeSlider2 = ({data: sliders, resourcePath}) => {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    pauseOnHover: false,
    prevArrow: <Arrow type="left" />,
    nextArrow: <Arrow type="right" />,
    lazyLoad: true,
  };
  return (
    <section className={`${classes.homeSliderBg}`}>
      <div className={`${classes.homeSliderMain}`}>
        <div className="content-holder">
          <Slider {...settings}>
            {sliders.map((item, i) => {
              return (
                <div className="home-slider-item" key={i + '__'}>
                  <div className="img-holder">
                    <div
                      className="bg-stretch background-image"
                      style={{
                        backgroundImage: `url('${resourcePath}/${item.image}')`,
                      }}
                    >
                      <div className="content container-fluid">
                        <h2 className="title">{item.title}</h2>
                        <br />
                        <p className="description">{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HomeSlider2;
