import React from 'react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import {makeStyles} from '@mui/styles';
import BannerImage from '../components/banner-image/1';
import useGallery from '../hooks/use-gallery';
import {useSWRConfig} from 'swr';

// style
const useStyles = makeStyles((theme) => ({
  galleryListBg: {
    padding: '90px 0 50px',
  },
  galleryListMain: {
    '& .content-holder': {
      '& .text-holder': {
        marginBottom: '50px',
        textAlign: 'center',
        '& .title': {
          fontSize: '32px',
          fontWeight: '700',
          color: '#434343',
        },
      },
      '& .marginBottom': {
        marginBottom: '30px',
        '& .box': {
          position: 'relative',
          boxShadow: `0 10px 50px rgb(166 209 237 / 20%)`,
          overflow: 'hidden',
          borderRadius: '15px',
          '& .img-holder': {
            height: '250px',
            width: '100%',
            '& a': {
              textDecoration: 'none',
              position: 'relative',
              '&::before': {
                content: "''",
                width: '100%',
                height: '50%',
                background: '#009f86',
                opacity: 0.6,
                transform: 'translateX(-50%) translateY(0%)',
                position: 'absolute',
                top: '-50%',
                left: '50%',
                zIndex: 1,
                clipPath: 'polygon(0 0, 50% 100%, 100% 0)',
                transition: 'all .3s',
              },
              '&::after': {
                content: "''",
                width: '100%',
                height: '50%',
                background: '#009f86',
                opacity: 0.6,
                transform: 'translateX(-50%) translateY(0%)',
                position: 'absolute',
                top: 'auto',
                left: '50%',
                bottom: '-50%',
                zIndex: 0,
                clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
                transition: 'all .3s',
              },
              '& .bg-stretch': {
                height: '100%',
                width: '100%',
                transform: 'scale(1)',
                transition: 'all 0.5s',
                position: 'relative',
                '& .box-content': {
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 'auto',
                  bottom: '-50%',
                  '&::before': {
                    content: "''",
                    width: '50%',
                    height: '100%',
                    background: '#009f86',
                    opacity: 0.6,
                    transform: 'translateX(0%) translateY(-100%)',
                    position: 'absolute',
                    top: '50%',
                    left: 'auto',
                    right: '-50%',
                    zIndex: 1,
                    clipPath: 'polygon(100% 0, 0 50%, 100% 100%)',
                    transition: 'all .3s',
                  },
                  '&::after': {
                    content: "''",
                    width: '50%',
                    height: '100%',
                    background: '#009f86',
                    opacity: 0.6,
                    transform: 'translateX(0%) translateY(-100%)',
                    position: 'absolute',
                    top: '50%',
                    left: '-50%',
                    right: 'auto',
                    zIndex: 0,
                    clipPath: 'polygon(0 100%, 100% 50%, 0 0)',
                    transition: 'all .3s',
                  },
                },
              },
              '& .content': {
                textAlign: 'center',
                width: '100%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                left: '50%',
                top: '50%',
                zIndex: 3,
                padding: '20px',
                opacity: 0,
                '& .title': {
                  color: '#fff',
                  fontSize: '18px',
                  fontWeight: 600,
                  textTransform: 'capitalize',
                  margin: '0 0 5px',
                  transform: 'rotateY(360deg)',
                  transition: 'all 0.5s',
                },
              },
            },
          },
          '&:hover': {
            '& .img-holder': {
              '& a': {
                '&::before': {
                  top: 0,
                },
                '&::after': {
                  bottom: 0,
                },
                '& .content': {
                  opacity: 1,
                  // '& .title': {
                  //   transform: 'rotateY(0)',
                  // },
                },
                '& .bg-stretch': {
                  transform: 'scale(1.5)',
                  '&::before': {
                    display: 'none',
                  },
                  '& .box-content': {
                    '&::before': {
                      right: 0,
                    },
                    '&::after': {
                      left: 0,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}));

const GalleryList = () => {
  const resourcePath = useSWRConfig().apiUrl.replace(
    '/api',
    '/uploads/gallery'
  );
  const {posts: gallery} = useGallery('photo');
  const classes = useStyles();
  return (
    <>
      <BannerImage baseMenu={{title: 'Gallery'}} title="Photo Gallery" />
      <section className={`${classes.galleryListBg}`}>
        <div className={`container-fluid ${classes.galleryListMain}`}>
          <div className="content-holder">
            {/* <div className="text-holder">
              <h2 className="title">Photo Gallery</h2>
            </div> */}
            <div className="row">
              {gallery.map((item, i) => {
                return (
                  <div
                    className="col-lg-4 col-md-12 col-sm-12 marginBottom"
                    key={i + '__'}
                  >
                    <Fade bottom duration={2000}>
                      <div className="box">
                        <div className="img-holder">
                          <Link to={`${item.id}`}>
                            <div
                              className="bg-stretch background-image"
                              style={{
                                backgroundImage: `url('${resourcePath}/${item.image}')`,
                              }}
                            >
                              <div className="box-content" />
                            </div>
                            <div className="content">
                              <h3 className="title">{item.title}</h3>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </Fade>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GalleryList;
