import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import PermPhoneMsgRoundedIcon from '@mui/icons-material/PermPhoneMsgRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import {makeStyles} from '@mui/styles';
import dayjs from 'dayjs';
import WebsiteContext from '../../../contexts/website-context';

// style
const useStyles = makeStyles((theme) => ({
  footerBg: {
    background: 'rgb(247 171 48 / 15%)',
    padding: '100px 0px 0px 0px',
    '& .footer-copy-right': {
      marginTop: '50px',
      background: `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .copy-right-date': {
        '& span': {
          color: '#fff',
        },
      },
      '& .powered-by': {
        display: 'flex',
        alignItems: 'center',
        '& .powered-by-content': {
          '& h6': {
            color: '#fff',
            margin: 0,
            fontSize: '16px',
          },
          '& .company': {
            '& a': {
              textDecoration: 'none',
              color: '#fff',
              fontSize: '18px',
              fontWeight: 700,
            },
          },
        },
      },
    },
    '& .marginTop': {
      marginTop: '150px',
    },
  },
  '@media(max-width: 600px)': {
    footerBg: {
      '& .footer-copy-right': {
        padding: '20px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .copy-right-date': {
          textAlign: 'center',
        },
      },
    },
  },
  '@media(min-width: 768px)': {
    footerBg: {
      '& .footer-copy-right': {
        padding: '20px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .copy-right-date': {
          width: '100%',
          textAlign: 'center',
        },
      },
    },
  },
  '@media(min-width: 992px)': {
    footerBg: {
      '& .footer-copy-right': {
        padding: '20px 100px',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        '& .copy-right-date': {
          width: 'unset',
          textAlign: 'left',
          marginBottom: '0',
        },
      },
    },
  },
  footerMain: {
    '& .content-holder': {
      '& .sologo-holder': {
        '& .logo-holder': {
          marginBottom: '20px',
          '& img': {
            height: '80px',
          },
        },
        '& .slogan-holder': {
          '& p': {
            margin: '0',
            padding: '0',
            color: '#434343',
            lineHeight: 2,
          },
        },
      },
      '& .footer-title': {
        marginBottom: '20px',
        '& h4': {
          color: '#434343',
          fontWeight: 500,
        },
      },
      '& .navigation-holder': {
        '& ul': {
          margin: '0',
          padding: '0',
          '& li': {
            marginBottom: '15px',
            listStyle: 'none',
            '&:last-child': {
              marginBottom: '0',
            },
            '& a': {
              color: '#434343',
              textDecoration: 'none',
              transition: 'all .3s ease-out',
              '&:hover': {
                color: theme.palette.primary.main,
              },
            },
          },
        },
      },
      '& .address-holder': {
        '& ul': {
          margin: '0',
          padding: '0',
          '& li': {
            listStyle: 'none',
            color: '#434343',
            '& .texticon-divider': {
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
              '& .icon-holder': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                marginRight: '15px',
                boxShadow: '8px 8px 30px 0px rgb(42 67 113 / 15%)',
                '& .MuiSvgIcon-root': {
                  fill: '#434343',
                },
              },
              '& .text': {
                '& h6': {
                  margin: '0',
                },
                '& a': {
                  color: '#434343',
                  textDecoration: 'none',
                  transition: 'all .3s ease-out',
                  '& h6': {
                    margin: '0',
                  },
                  '&:hover': {
                    color: theme.palette.primary.main,
                  },
                },
              },
            },
          },
        },
      },
      '& .download-holder': {
        '& ul': {
          margin: '0',
          padding: '0',
          '& li': {
            listStyle: 'none',
            display: 'inline-block',
            '& .download-link': {
              marginRight: '15px',
              width: '100px',
              height: '100px',
              display: 'block',
              borderRadius: '10px',
              transition: 'all 2s ease-out',
            },
          },
        },
      },
      '& .social-networks': {
        '& li a': {
          width: '40px',
          height: '40px',
          lineHeight: '40px',
          backgroundColor: theme.palette.secondary.main,
          color: '#fff',
          display: 'inline-block',
          textAlign: 'center',
          transition: 'all ease-out .4s',

          '&:hover': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },
    '@media(max-width: 600px)': {
      '& .content-holder': {
        '& .sologo-holder': {
          marginBottom: '20px',
        },
        '& .navigation-holder': {
          marginBottom: '20px',
        },
      },
    },
    '@media(min-width: 768px)': {
      '& .content-holder': {
        '& .sologo-holder': {
          marginBottom: '20px',
        },
        '& .navigation-holder': {
          marginBottom: '20px',
        },
      },
    },
    '@media(min-width: 992px)': {
      '& .content-holder': {
        '& .sologo-holder': {
          marginBottom: '0',
        },
        '& .navigation-holder': {
          marginBottom: '0',
        },
      },
    },
  },
}));

const Footer = () => {
  const {profile, menu} = useContext(WebsiteContext);
  const classes = useStyles();

  return (
    <section className={`${classes.footerBg}`}>
      <div className={`container-fluid ${classes.footerMain}`}>
        <div className="content-holder">
          <div className="row justify-content-between">
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="sologo-holder">
                <div className="logo-holder">
                  <img
                    src={`${profile.logo_path}/${profile.logo}`}
                    alt={`${profile.title} Logo`}
                    className="img-fluid"
                  />
                </div>
                <div className="slogan-holder">
                  <p>{profile.slogan}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="footer-title">
                <h4>Useful Link</h4>
              </div>
              <div className="navigation-holder">
                <nav className="navigation">
                  <ul>
                    {menu.map((item, i) => {
                      let slug = item.slug;
                      if (item.subMenu && item.subMenu.length > 0) {
                        let subMenu = item.subMenu[0];
                        if (subMenu.menuLine && subMenu.menuLine.length > 0) {
                          let menuLine = subMenu.menuLine[0];
                          slug = menuLine.slug;
                        } else {
                          slug = subMenu.slug;
                        }
                      }
                      return (
                        <li key={i + '__'}>
                          <Link to={slug ?? '#'}>{item.name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
              <div className="address-holder pb-2">
                <div className="footer-title">
                  <h4>Contact Info</h4>
                </div>
                <ul>
                  <li>
                    <div className="texticon-divider">
                      <div className="icon-holder">
                        <RoomRoundedIcon />
                      </div>
                      <div className="text">
                        <a
                          href={`http://maps.google.com/?q=${profile.address}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {profile.address}
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="texticon-divider">
                      <div className="icon-holder">
                        <PermPhoneMsgRoundedIcon />
                      </div>
                      <div className="text">
                        <a href={`tel:${profile.phone}`}>
                          <span>{profile.phone}</span>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="texticon-divider">
                      <div className="icon-holder">
                        <MailOutlineRoundedIcon />
                      </div>
                      <div className="text">
                        <a href={`mailto:${profile.email}`}>
                          <span>{profile.email}</span>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="social-networks">
                <h4 className="network-title">Follow Us</h4>
                <ul className="list-inline">
                  {profile.fb_url && (
                    <li className="list-inline-item">
                      <a href={profile.fb_url} target="_blank" rel="noreferrer">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                  )}
                  {profile.yt_url && (
                    <li className="list-inline-item">
                      <a href={profile.yt_url} target="_blank" rel="noreferrer">
                        <i className="fa fa-youtube" />
                      </a>
                    </li>
                  )}
                  {profile.twitter_url && (
                    <li className="list-inline-item">
                      <a
                        href={profile.twitter_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                  )}
                  {profile.insta_url && (
                    <li className="list-inline-item">
                      <a
                        href={profile.insta_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copy-right">
        <div className="copy-right-date">
          <span>
            © Copyright {dayjs().format('YYYY')} {profile.title}. All rights
            reserved.
          </span>
        </div>
      </div>
    </section>
  );
};

export default Footer;
